.a-preamble {
    line-height: $preamble-line-height;
    font-size: $preamble-font-size;
    font-family: $font-family-roboto-regular;
    margin-bottom: 0;

    &.a-preamble-line:after {
        border-style: solid;
        border-width: 0;
        border-top-width: 3px;
        width: 40%;
        content: " ";
        display: block;
        margin-bottom: 2rem;
        margin-top: 1.25rem;
        border-color: $background-color;
    }

    @include media-breakpoint-down(md) {
        font-size: $preamble-font-size-m;
        line-height: $preamble-line-height-m;
    }
}
