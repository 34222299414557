﻿.sharing-options-container {
    background-color: white;
    margin-top: 1.5rem;
    margin-left: 25px;

    @include media-breakpoint-down(md) {
        margin-left: 13px;
    }

    @include media-breakpoint-down(sm) {
        margin-left: auto;
        margin-bottom: 0;
        margin-right: auto;
        text-align: center;
    }

    .social-media-icons {
        margin-bottom: 10px;
        display: inline-block;
    }

    .o-some__icon {
        background-size: 495px;
        width: 35px;
        height: 35px;
        margin-right: 0.2rem;
        margin-left: 0.2rem;
        font-size: 37px;        
        display: inline-block;

        @include media-breakpoint-up(md) {
            margin-right: 0;
            margin-left: 0;
        }
    }

    a {
        text-decoration: none;
        color: transparent;
        display: inline-block;
    }
}
