﻿.kontakt-page {
    .m-toppseksjon {
        margin-bottom: 1rem;
    }

    .kontakt-page__subheading {
        margin-top: 66px;
        margin-bottom: 0;
        display: inline-block;
    }

    .kontakt-page-content {
        padding-top: 2.5rem;
        background-color: $light-gray;
        display: none;

        &.active {
            display: block;
        }
    }

    .o-categories-container {
        background-color: $white;
        padding-bottom: 0;
        }
    }

.kontakt-page {
    .m-contact-info {
        margin-top: 1.25rem;

        .m-contact-info__description {
            margin-bottom: 2rem;
        }
    }

    .kart-section {
        margin-bottom: 2rem;
    }
}
