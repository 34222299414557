input[type=checkbox] {
    -webkit-appearance: none;
    background-color: $lighter-pink;
    padding: 11px;
    display: inline-block;
    position: relative;
    border: 5px solid $lighter-pink;
    outline: none;
    margin: 0 20px 0 10px;
    vertical-align: bottom;
    float: left;
    top: -2px;

    &:active, &:checked:active, &:checked {
        background-color: $main-color;
    } 
}
