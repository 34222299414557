﻿/*
    Aspect ratio mixin
    Used to have a constant aspect ratio on different screens
    Best used together with images/cutting of images with a given aspect ratio (1200 x 675 on Aktuelt)
*/

@mixin aspect-ratio($width, $height) {
    position: relative;

    &:before {
        display: block;
        content: " ";
        width: 100%;
        padding-top: ($height / $width) * 100%;
    }
}
