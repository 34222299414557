.o-support-menu {
    position: relative;
    padding-top: 0;

    &__logo {
        &__link {
            display: block;
            margin-top: 0.5rem;
            margin-bottom: 0.5rem;
            float: left;
        }

        &__img {
            width: auto;
            max-height: 75px;
            max-width: 150px;
            min-height: 47px;
            min-width:150px;

            @include media-breakpoint-up(sm) {
                min-height: 50px;
                min-width: 222px;
                max-width: none;
            }

            @include media-breakpoint-up(lg) {
                margin-bottom: 0.5rem;
            }
        }
    }

    &__right-links {
        display: flex;
        align-items: center;
        align-self: flex-end;


        @include media-breakpoint-up(lg) {
            display: block;
            align-items: baseline;
            align-self: baseline;
        }

        label {
            margin: 0;
        }
    }

    &__link {
        color: $black;
        font-weight: 500;
        text-decoration: none;
        padding: 1rem 0 0.5rem 0;

        &:hover {
            color: $main-color;
            text-decoration: underline;
        }
    }

    &__vis-sok-link {
        padding-right: 0;

        &__icon {
            margin-left: 0;
            display: none;

            &--mobile {
                @extend .i-search-mobile-white;
            }
        }
    }

    .collapsed {
        .o-support-menu__vis-sok-link__icon--mobile {
            @extend .i-search-mobile;
        }
    }

    .navbar-nav {
        display: flex;

        li {
            padding-left: 1rem;
            padding-right: 1rem;
        }
    }

    li:not(.o-support-menu__vis-sok-link) {
        display: none;
    }

    @include media-breakpoint-up(lg) {
        justify-content: space-between;
        border-bottom: 0;
        padding-top: 0.8rem;

        li:not(.o-support-menu__vis-sok-link) {
            display: block;
        }

        .navbar-nav {
            .o-support-menu__vis-sok-link__icon {
                display: inline-block;
            }

            .o-support-menu__vis-sok-link__icon--mobile {
                display: none;
            }
        }
    }

    .header-section {
        @include media-breakpoint-down(md) {
            display: none;
        }
    }

    .header-mobile {
        @include media-breakpoint-up(lg) {
            display: none;
        }
    }
}
