.a-wide-container {    
    @include make-container();
    padding-left: 100px;
    padding-right: 100px;
}

.a-wide-container--inner {
    max-width: 1360px;
    width: calc(100% + 200px);    
    margin-left: -100px;
    margin-right: -100px;
}

.a-wide-container--grey {
    background-color: $light-gray;
    padding-left: 0;
    padding-right: 0;
}