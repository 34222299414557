﻿.form.container {
    padding: 2rem 0;
}

form {
    // Initial styling for all form block containers
    &.EPiServerForms, .form.container & {
        background: $lighter-pink;
        padding: 2rem 100px;

        @include media-breakpoint-down(sm) {
            padding: 0 2rem 2rem;
        }

        .form & {
            background: transparent;
            padding: 0;
            margin: 0;

            .Form {
                &__Title, &__Description,
                &__Status, &__MainBody {
                    max-width: 100%;
                }
            }
        }

        .Form {
            &__Title, &__Description,
            &__Status, &__MainBody {
                margin: 0 auto;
                max-width: $screen-sm;
            }
        }
    }
    // Initial styling for form elements, used in Vue form app
    label {
        margin-bottom: 0;
    }

    .disabled {
        label, input, select {
            opacity: 0.5;
        }
    }

    .form__top {
        margin-top: 2rem;
        margin-bottom: 2rem;
    }

    .form__header {
        color: $theme-red;
        text-align: center;
        margin: 2.5rem 0 2rem 0;
    }

    .form__header--2 {
        color: $theme-red;
        text-align: center;
        margin: 2rem 0 0.5rem 0;
    }

    .form__preamble {
        &::after {
            border-color: $white;
        }
    }

    .form__info {
        margin: 2rem 0 1rem 0;
    }

    .form__background {
        background-color: $lighter-pink;
        padding-bottom: 4rem;
    }

    .form__border {
        border-bottom: 5px solid $theme-red;
    }

    .form__fields-row {
        margin-left: - $spacer / 2;
        margin-right: - $spacer / 2;
    }

    .form__fields-col {
        padding-left: $spacer / 2;
        padding-right: $spacer / 2;
        margin-top: $spacer / 2;
        margin-bottom: $spacer / 2;

        &--x {
            padding-left: $spacer / 2;
            padding-right: $spacer / 2;
        }

        &--y {
            margin-top: $spacer / 2;
            margin-bottom: $spacer / 2;
        }
    }

    .form__buttons-single-choice {
        label, input {
            text-align: center;
        }

        label {
            &:hover {
                cursor: pointer;
            }
        }

        input[type="radio"] {
            display: none;
        }

        .form__button-background {
            background-color: $white;
            padding: 0.7rem;
            display: block;
        }

        input:checked + label .form__button-background {
            background-color: $theme-red;
            color: $white;
        }
    }

    .form__small-description {
        font-size: 0.85rem;
        margin: 0;
    }

    .form__field {
        width: 100%;
        background-color: $white;
        border: 0;
        padding: 0.7rem;
        margin-top: $spacer / 2;
        margin-bottom: $spacer / 2;
    }

    select.form__field {
        cursor: pointer;
        // Hardcoded arrow when using appearance: none;
        -webkit-appearance: none;
        background-image: url('data:image/svg+xml;utf8,<?xml version="1.0" encoding="utf-8"?><svg xmlns="http://www.w3.org/2000/svg" width="14" height="12" version="1"><path d="M4 8L0 4h8z"/></svg>');
        background-repeat: no-repeat;
        border-radius: 0;
        background-position: right 50%;
        background-size: 23px;
    }

    .form__add {
        background-color: $white;
    }

    .form__checkbox {
        display: flex;
        align-items: center;
        margin-bottom: 2rem;

        .form__checkbox__label {
            cursor: pointer;
        }

        .form__checkbox__input {
            -webkit-appearance: none;
            background-color: $white;
            padding: 11px;
            display: inline-block;
            position: relative;
            border: 5px solid $white;
            outline: none;
            margin: 0 20px 0 0;
            vertical-align: bottom;
            float: left;
            top: -2px;
            cursor: pointer;

            &:active, &:checked:active, &:checked {
                background-color: $main-color;
                border: 5px solid $white;
            }
        }
    }

    .form__input-inner-text {
        position: absolute;
        top: 50%;
        right: 40px;
        transform: translate(0, -50%);
    }

    .form__errors {
        &--summary, ul {
            background-color: #FFF3DB;
            border: 2px solid #EB9A00;
            padding: 2rem 2rem 1rem 2rem;
            margin-bottom: 2rem;
        }

        .form__errors__header {
            color: $theme-red;
            margin: 2.5rem 0 2rem 0;
            font-size: 1.5rem;
        }

        .form__errors__error, li {
            font-weight: bold;
            margin-left: 1rem;
        }
    }
}
// Styling for EPiServer form elements
.EPiServerForms {
    // Due to issues with data not being sent in if we use custom views for form elements, we override their styling here.
    .Form__Element {
        // Override default validation error
        span.Form__Element__ValidationError, .FormTextbox.ValidationFail input {
            background-color: $warning-background;
            border: 2px solid $warning-border;
            color: black;
        }

        span.Form__Element__ValidationError {
            background-color: $warning-background;
            border: 2px solid $warning-border;
            color: black;
            font-weight: bold;
            text-align: center;
            padding: 1rem 0;
            margin: 1rem 100px 0;
        }
        // TextboxElementBlock
        &.FormTextbox {
            input {
                height: 45px;
                background-color: $white;
                border: 0;
                margin-bottom: .5rem;
                margin-top: .5rem;
                padding: .7rem;
                width: 100%;
            }

            label {
                font-size: 15px;
            }
        }
        // Checkboxes
        &.FormChoice {
            & > label {
                margin: 15px 10px 0 0;

                @include media-breakpoint-down(xs) {
                    width: 100%;
                    margin: 15px 0 0 0;
                }
            }

            .Form__Element__Caption {
                display: inline-block;
                width: 100%;
                margin-bottom: 0.5rem;
            }

            input[type=checkbox].FormChoice__Input.FormChoice__Input--Checkbox {
                -webkit-appearance: none;
                background-color: $white;
                padding: 11px;
                display: inline-block;
                position: relative;
                border: 5px solid $white;
                outline: none;
                margin: 0 15px 0 0;
                vertical-align: bottom;
                float: left;
                top: -2px;

                &:active, &:checked:active, &:checked {
                    background-color: $main-color;
                    border: 5px solid $white;
                }
            }
        }
    }

    button.FormSubmitButton {
        text-align: center;
        padding: 1rem 2.25rem;
        text-transform: uppercase;
        border: 2px solid $main-color;
        color: $main-color;
        display: block;
        text-decoration: none;
        border-radius: 100px;
        line-height: 1.31rem;
        font-weight: 600;
        background-color: transparent;
        cursor: pointer;
        margin: 0 auto;

        &:hover {
            background-color: $main-color;
            color: $white;
            text-decoration: none;
            font-weight: 500;
        }
    }
}