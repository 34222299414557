﻿/* Utsyn is the only page that diverges from the color palette. We have therefore chosen to put all styling pertaining to the UtsynArtikkelPage within this file */
.utsyn {
    $main-color: $utsyn-blue;
    $background-color: $lighter-blue;
    $small-element-color: $light-blue;

    .artikkeltekst {

        .m-breadcrumb {
            .m-breadcrumb__name:hover, .m-breadcrumb__item:hover {
                color: $main-color;
            }
        }

        .byline {
            .author-name, .photographer-name, .omraade {
                color: $main-color;
            }
        }

        .a-preamble-line {
            &:after {
                border-color: $background-color;
            }
        }

        blockquote {
            color: $main-color;

            &:before, &:after {
                border-color: $background-color;
            }
        }

        .m-color-box-container {
            border-color: $background-color;

            a.tag:hover {
                background-color: $background-color;
            }
        }


        .artikkeltekst-innhold > .sideimage {
            @include media-breakpoint-up(xl) {

                .m-article-image__photographer {
                    border-bottom: 3px solid $background-color;
                }
            }
        }
    }

    .m-card, .m-card-infoblock {
        color: $main-color;
        background-color: $background-color;

        .m-card__title {
            color: $main-color;
        }

        .btn-container {
            a {
                color: $main-color;
                border-color: $main-color;

                &:hover {
                    background-color: $main-color;
                    color: $white;
                }
            }
        }
    }

    .i-arrow-down {
        @extend .i-arrow-down-blue;
    }
}
