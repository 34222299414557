﻿.o-navigation-menu {
    $nav-menu: &;
    padding-top: 0;
    padding-bottom: 0;

    &__ul {
        padding: 0 1.25rem;
    }

    &__li {
        margin-left: -20px;
        margin-right: -20px;
        padding-left: 0;
        padding-right: 0;

        @include media-breakpoint-up(lg) {
            margin-left: 0;
            margin-right: 0;
            padding-left: 0;
            padding-right: 0;
        }

        &.active {
            #{$nav-menu}__link {
                border-top: 0;
            }
        }
    }

    &__link {
        $link: &;
        border-top: 1px solid $small-element-color;
        text-align: left;
        padding-left: 20px;
        padding-right: 20px;

        @include media-breakpoint-up(lg) {
            text-align: center;
            display: flex;
        }

        & > span {
            flex: 1 1 auto;
            text-align: center;
        }

        &-icon, #{$nav-menu}__megamenu-link-icon {
            float: right;
            flex-basis: 27px;

            @include media-breakpoint-up(lg) {
                float: none;
                margin-right: 0;
            }

            @include media-breakpoint-up(xl) {
                margin-right: 0.5rem;
            }
        }

        &:hover {
            background-color: $small-element-color;

            #{$link}-icon {
                @extend .i-arrow-right-white;
            }

            #{$nav-menu}__megamenu-link-icon {
                @extend .i-arrow-down-white;
            }
        }

        &.active {
            background-color: $main-color;
            color: blue;

            #{$nav-menu}__megamenu-link-icon {
                @extend .i-arrow-up-white;
            }
        }
    }

    &.navbar {
        .nav-item.active:hover {
            & > .nav-link {
                color: $black;
            }
        }
    }

    .navbar-nav .nav-link {
        padding-left: 1rem;
        padding-right: 1rem;

        @include media-breakpoint-up(lg) {
            padding-left: 0;
            padding-right: 0;
        }
    }

    .o-support-menu-item-mobile {
        background-color: $small-element-color;

        &:last-child {
            border-bottom: 3px solid $main-color;
        }

        a {
            border-bottom: 1px solid $white;
        }
    }

    &.show {
        @include media-breakpoint-up(md) {
            border-bottom: 0;
            margin-bottom: 0;
        }
    }

    .show {
        margin-bottom: 0;

        @include media-breakpoint-up(lg) {
            border-bottom: 0;
        }
    }

    a {
        text-decoration: none;
        font-size: 1.125rem;
        letter-spacing: 1px;
        font-weight: 500;

        @include media-breakpoint-up(lg) {
            text-align: center;
        }
    }
}

.o-navigation-sub-menu {
    ul.container {
        margin-bottom: 0;
    }
}


@include media-breakpoint-up(lg) {
    .o-support-menu-item-mobile {
        display: none;
    }
}
