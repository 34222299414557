.ansatt-page {
    &__contact-info {
        &--phone {
            margin-bottom: 0.85rem;
        }

        &--email {
            word-break: break-all
        }
    }

    &__details {
        width: 100%;

        @include media-breakpoint-up(lg) {
            max-width: 60%;
        }

        li {
            border-bottom: 1px solid $lighter-pink;
            padding: 1.5rem 0;
        }

        .i-icon {
            margin-right: 0.3rem;
        }
    }

    &__contact-info, &__details {
        a {
            text-decoration: none;
            color: $black;

            &:hover {
                text-decoration: underline;
            }
        }
    }
}
