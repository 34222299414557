﻿.search-hits {
    .global-search-hit {
        background-color: $white;
        color: $black;
        padding: 2rem 0;
        height: 17.94rem;
        object-fit: cover;
        margin-bottom: 1.875rem;
        display: block;
        text-decoration: none;
        border-bottom: 1px solid $light-pink;

        &:hover {
            .read-more-link__text {
                text-decoration: underline;
            }
        }

        @media (max-width: $screen-md-min) {
            height: initial;
        }

        figure {
            @include aspect-ratio(1,1);
            width: 230px;
            float: left;
            margin: 0 2rem 0 0;

            @media (max-width: $screen-md-min) {
                @include aspect-ratio(16,9);
                width: 100%;
                margin: 0 0 1rem;
            }
        }

        img {
            position: absolute;
            top: 0;
            float: left;
        }

        &__text {
            padding-right: 1.25rem;
            margin-bottom: 0.5rem;
            text-decoration: none;

            @media (max-width: $screen-lg-min) {
                min-height: initial;

                .read-more-link {
                    margin-top: 1.25rem;
                }
            }

            p {
                max-height: 6rem;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }

        &__heading {
            margin-top: 1.5rem;
            display: inline-block;
        }

        .read-more-link {
            line-height: 1.188rem;
            text-decoration: none;
            color: $black;
            font-weight: 500;

            &__text {
                margin-right: 0.3125rem;
                display: inline-block;
                vertical-align: middle;
            }

            .i-icon {
                font-size: 1.188rem;
            }
        }

        .pipe {
            padding: 0 1rem 0 1rem;
        }
    }
}
