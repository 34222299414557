// Hyphenating headings on mobile
h1, h2, h3, h4, h5, h6, h7, h8,
.h1, .h2, .h3, .h4, .h5, .h6, .h7, .h8 {
    hyphens: auto;
}

// Properties that allies everywhere
h6, .h6 {
    letter-spacing: $h6-letter-spacing;
    font-family: $font-family-roboto-medium;
}

.h7 {
    letter-spacing: $h7-letter-spacing;
    font-family: $font-family-roboto-medium;
    text-transform: uppercase;
}

.h8 {
    letter-spacing: $h8-letter-spacing;
    font-family: $font-family-roboto-medium;
    text-transform: uppercase;
}


// screen width is less than 992px wide
@include media-breakpoint-down(lg) {

    .h1 {
        line-height: $h1-line-height-m;
        font-size: $h1-font-size-m;
    }

    .h2 {
        line-height: $h2-line-height-m;
        font-size: $h2-font-size-m;
    }

    .h3 {
        line-height: $h3-line-height-m;
        font-size: $h3-font-size-m;
    }

    .h4 {
        line-height: $h4-line-height-m;
        font-size: $h4-font-size-m;
    }

    .h5 {
        line-height: $h5-line-height-m;
        font-size: $h5-font-size-m;
    }

    .h6 {
        line-height: $h6-line-height-m;
        font-size: $h6-font-size-m;
    }

    .h7 {
        line-height: $h7-line-height-m;
        font-size: $h7-font-size-m;
    }

    .h8 {
        line-height: $h8-line-height-m;
        font-size: $h8-font-size-m;
    }
}

// screen width is at least 992px wide
@include media-breakpoint-up(lg) {
    .h1 {
        font-size: $h1-font-size;
        line-height: $h1-line-height;
    }

    .h2 {
        font-size: $h2-font-size;
        line-height: $h2-line-height;
    }

    .h3 {
        font-size: $h3-font-size;
        line-height: $h3-line-height;
    }

    .h4 {
        font-size: $h4-font-size;
        line-height: $h4-line-height;
    }

    .h5 {
        font-size: $h5-font-size;
        line-height: $h5-line-height;
    }

    .h6 {
        font-size: $h6-font-size;
        line-height: $h6-line-height;
    }

    .h7 {
        line-height: $h7-line-height;
        font-size: $h7-font-size;
    }

    .h8 {
        line-height: $h8-line-height;
        font-size: $h8-font-size;
    }
}
