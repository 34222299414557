.o-fremhevet-punkt {

    ul {
        list-style: none;
        padding-left: 0;
    }

    .m-submenu__header {
        border-bottom: 2px solid $main-color;
        padding-bottom: 19px;
        padding-top: 0;
        text-align: center;
        margin-bottom: 0;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .m-submenu__link {
        text-decoration: none;
        color: $black;
        border-bottom: 2px solid $light-gray;
        font-size: 1.125rem;
        margin-bottom: 0;
        display: block;
        position: relative;
        padding-top: 0.8rem;
        padding-bottom: 0.8rem;

        &:hover {
            background-color: $small-element-color;
            padding-left: 1.25rem;
            padding-right: 1.25rem;
        }
    }

    .m-submenu__icon {
        position: absolute;
        right: 20px;
        top: calc(50% - 11.5px);
    }

    .m-submenu__link:hover {
        .m-submenu__icon {
            right: 15px;
            @extend .i-arrow-right-white;
        }
    }

    @include media-breakpoint-down(md) {
        width: 80%;
        margin: 1.25rem auto 0;
    }
}
