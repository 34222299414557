.skribent-page {
    background-color: $light-gray;
    padding-top: 2rem;

    .se-alle-skribenter-link {
        line-height: 1.188rem;
        padding-right: 2rem;
        text-decoration: none;
        color: $black;
        font-weight: 500;

        .se-alle-skribenter-link-text {
            margin-left: 0.3125rem;
            display: inline-block;

            &:hover {
                text-decoration: underline;
            }
        }

        .i-arrow-right-red {
            transform: rotate(180deg);
        }
    }
}
