﻿.aktuelt-page, .artikkellist-page {
    margin-top: 2.5rem;

    .a-aktuelt-navbar-toggle:not(.collapsed) {
        margin-right: -20px;
    }

    .tilbake-til-aktuelt {
        line-height: 1rem;
        margin: 2.5rem 2rem 1.5rem 2rem;
        text-decoration: none;
        color: $black;
        font-weight: 500;
        display: inline-block;

        .tilbake-til-aktuelt-lenketekst {
            margin-left: 5px;
            display: inline-block;
        }

        .i-icon {
            font-size: 1rem;
            transform: rotate(180deg);
        }
    }

    .aktuelt-page-content {
        background-color: $light-gray;
    }

    @media (min-width: $screen-lg-min) {
        .mobile, .aktuelt-header, .tilbake-til-aktuelt {
            display: none;
        }

        .container {
            padding-left: 0;
            padding-right: 0;
        }
    }

    @media (max-width: $screen-md-min) {
        margin-top: 0.25rem;

        .desktop {
            display: none;
        }
    }
}

.aktuelt-page {
    @media (max-width: $screen-md-min) {

        .aktuelt-page-content {
            border-top-width: 0;
        }

        .o-aktuelt-navigation-menu .o-aktuelt-navigation-menu__li {
            padding: 0;

            .o-aktuelt-navigation-menu__link {
                text-align: left;
                padding-left: 2rem;
                padding-right: 2rem;
            }
        }

        .aktuelt-header {
            padding-bottom: 0;

            .h1 {
                margin-top: 20px;
                display: inline-block;
            }

            .a-aktuelt-navbar-toggle.collapsed, .a-aktuelt-navbar-toggle {
                .a-aktuelt-navbar-toggle__burger-container {
                    margin-top: -5px;
                }
            }

            
        }
    }

    .aktuelt-page-content {
        padding-top: 2.5rem;
    }
}

.artikkellist-page {
    .o-aktuelt-navigation-menu.mobile .o-aktuelt-navigation-menu__ul-wrapper:not(.show) + .separator {
        border-top: 4px solid $main-color;
    }
}
