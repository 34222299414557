.skjema-page {
    .skjema-page__background {
        background-color: $lighter-pink;
        padding-bottom: 4rem;
    }

    .artikkeltekst {
        padding-bottom: 3rem;
        margin-bottom: 1rem;
    }

    .form {
        padding: 0 5%;

        @include media-breakpoint-up(sm) {
            padding: 0 100px;
        }


        h2 {
            color: $theme-red;
        }
    }
}
