.a-wide-container--grey > .separator{
    background-color: $light-gray;

    &:before {
        border-style: solid;
        border-width: 0;
        border-top-width: 1px;
        width: 90%;
        content: " ";
        margin: auto;
        border-color: $dark-gray;
        display: block;
    }
}

.a-wide-container--grey > .separator:first-of-type {
    display: none;
}
