.aktuelt-header {
    width: 100%;
    padding-bottom: 15px;

    a {
        color: $main-color;
        text-decoration: underline;
    }

    .a-aktuelt-navbar-toggle {
        display: block;
        margin-left: auto;
        margin-right: 0;

        .h1 {
            padding-left: 20px;
        }

        @include media-breakpoint-up(lg) {
            display: none;
        }

        .a-aktuelt-navbar-toggle__burger-container {
            float: right;
            position: absolute;
            padding: 20px 20px 8px;
            right: 0;
            top: 15px;

            .burger-menu {
                position: relative;
                height: 30px;
                width: 30px;
                right: 0;

                span {
                    position: absolute;
                    display: inline-block;
                    width: 100%;
                    height: 5px;
                    background-color: $main-color;
                    border-radius: 35px;
                    transform-origin: left;
                    transition: transform 0.5s;
                    display: inline-block;
                    margin-bottom: 2px;
                    transition: transform 0.5s;

                    &:nth-child(1) {
                        transform: rotate(40deg);
                        top: 0;
                    }

                    &:nth-child(2) {
                        display: none;
                        top: 10px;
                    }

                    &:nth-child(3) {
                        transform: rotate(-40deg);
                        top: 20px;
                    }
                }
            }
        }

        &.collapsed {
            background-color: transparent;

            .h1 {
                display: block;
                float: left;
                margin-right: .5rem;
            }

            .a-aktuelt-navbar-toggle__burger-container {
                background-color: $white;

                .burger-menu {
                    span {
                        display: inline-block;
                        height: 5px;
                        margin-bottom: 2px;
                        width: 100%;
                        border-radius: 35px;
                        position: absolute;
                        transition: transform 0.5s;
                        background-color: $main-color;

                        &:nth-child(1) {
                            transform: rotate(0deg);
                        }

                        &:nth-child(2) {
                            display: block;
                        }

                        &:nth-child(3) {
                            transform: rotate(0deg);
                        }
                    }
                }
            }
        }
    }

    @include media-breakpoint-down(md) {
        .aktuelt-header-toggle-link {

            &.collapsed {
                background-color: transparent;
                color: $black !important;
            }
        }
    }

    .aktuelt-header-toggle-link {
        background-color: transparent;
        text-decoration: none;
        margin-left: auto;
        margin-right: 0;
    }
}
