﻿.o-filtering-side-meny {
    background-color: $white;
    padding: 2.625rem 2rem 1.875rem;
    font-family: $font-family-roboto-regular;

    @media (max-width: $screen-lg-min) {
        margin-bottom: 1.875rem;
    }

    &__valg {
        $filtering-valg: &;

        ul {
            padding: 0;
        }

        li {
            display: block;
            position: relative;
            line-height: 1.563rem;
            margin-bottom: 0.9375rem;
            font-size: 1.375rem;
            user-select: none;

            &:first-child {
                margin-top: 0.625rem;
            }

            > label {
                font-size: 1rem;
                line-height: 1.563rem;
                margin: 0;
                cursor: pointer;
            }

            + span {
                margin-top: 1rem;
            }

            &#{$filtering-valg}__indented {
                & + li:not(#{$filtering-valg}__indented) {
                    margin-top: 1.5rem;
                }

                > label {
                    font-size: 0.85rem;

                    > input {
                        padding: 8px;
                    }
                }
            }
        }
    }

    &__checkbox {
        height: 0;
        width: 0;
        padding: 0;
        margin: 0;
        border: 0;

        label:hover &, &:focus {
            background: lighten($red, 50%);
        }
    }

    &__checkbox-substitute {
        background-color: $lighter-pink;
        border: 5px solid $lighter-pink;
        display: inline-block;
        float: left;
        margin: 0 20px 0 10px;
        padding: 0;
        outline: none;
        padding: 11px;
        position: relative;
        top: -2px;
        vertical-align: bottom;
    }

    &__checkbox:checked {
        + &__checkbox-substitute::after {
            background-color: $red;
            width: 95%;
            height: 95%;
            top: 2.5%;
            right: 2.5%;
            bottom: 2.5%;
            left: 2.5%;
            display: block;
            position: absolute;
            content: " ";
        }

        label:hover &, &:focus {
            background: darken($red, 10%);
        }
    }

    $search: ".m-search";

    #{$search}__group {
        border-radius: 1.875rem;
        background-color: $background-color;
        margin-bottom: 2.188rem;

        #{$search}__text {
            height: 3.125rem;
            border: 2px solid $background-color;
            background-color: $background-color;
            border-radius: 2.188rem;
            letter-spacing: 0.05rem;
        }

        #{$search}__btn {
            z-index: 10;
            border-radius: 2.188rem;
            margin: 0;
            height: 2.5rem;
            height: 2.5rem;
            cursor: pointer;
            background-color: transparent;
            border: 0 solid transparent;
            margin-left: -3.125rem;
        }

        .i-search-big {
            font-size: 2.438rem;
        }
    }
}

.search-filtrering {
    width: 100%;
    background-color: $light-gray;
    padding-top: 2.5rem;
    padding-bottom: 3.75rem;
}
