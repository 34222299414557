/* roboto-regular - latin_latin-ext */
@font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    src: url('../../../Resources/fonts/roboto-v16-latin_latin-ext-regular.eot'); /* IE9 Compat Modes */
    src: local('Roboto'), local('Roboto-Regular'), url('../../../Resources/fonts/roboto-v16-latin_latin-ext-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('../../../Resources/fonts/roboto-v16-latin_latin-ext-regular.woff2') format('woff2'), /* Super Modern Browsers */
    url('../../../Resources/fonts/roboto-v16-latin_latin-ext-regular.woff') format('woff'), /* Modern Browsers */
    url('../../../Resources/fonts/roboto-v16-latin_latin-ext-regular.ttf') format('truetype'), /* Safari, Android, iOS */
    url('../../../Resources/fonts/roboto-v16-latin_latin-ext-regular.svg#Roboto') format('svg'); /* Legacy iOS */
}
/* roboto-italic - latin_latin-ext */
@font-face {
    font-family: 'Roboto Italic';
    font-style: italic;
    font-weight: 400;
    src: url('../../../Resources/fonts/roboto-v16-latin_latin-ext-italic.eot'); /* IE9 Compat Modes */
    src: local('Roboto Italic'), local('Roboto-Italic'), url('../../../Resources/fonts/roboto-v16-latin_latin-ext-italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('../../../Resources/fonts/roboto-v16-latin_latin-ext-italic.woff2') format('woff2'), /* Super Modern Browsers */
    url('../../../Resources/fonts/roboto-v16-latin_latin-ext-italic.woff') format('woff'), /* Modern Browsers */
    url('../../../Resources/fonts/roboto-v16-latin_latin-ext-italic.ttf') format('truetype'), /* Safari, Android, iOS */
    url('../../../Resources/fonts/roboto-v16-latin_latin-ext-italic.svg#Roboto') format('svg'); /* Legacy iOS */
}
/* roboto-500 - latin_latin-ext */
@font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    src: url('../../../Resources/fonts/roboto-v16-latin_latin-ext-500.eot'); /* IE9 Compat Modes */
    src: local('Roboto Medium'), local('Roboto-Medium'), url('../../../Resources/fonts/roboto-v16-latin_latin-ext-500.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('../../../Resources/fonts/roboto-v16-latin_latin-ext-500.woff2') format('woff2'), /* Super Modern Browsers */
    url('../../../Resources/fonts/roboto-v16-latin_latin-ext-500.woff') format('woff'), /* Modern Browsers */
    url('../../../Resources/fonts/roboto-v16-latin_latin-ext-500.ttf') format('truetype'), /* Safari, Android, iOS */
    url('../../../Resources/fonts/roboto-v16-latin_latin-ext-500.svg#Roboto') format('svg'); /* Legacy iOS */
}

@font-face {
    font-family: 'Leitura News';
    font-style: normal;
    font-weight: normal;
    src: url('../../../Resources/fonts/LeituraNews-Roman4.eot'); /* IE9 Compat Modes */
    src: local('Leitura News'), local('Leitura-News'), url('../../../Resources/fonts/LeituraNews-Roman4.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('../../../Resources/fonts/LeituraNews-Roman4.woff2') format('woff2'), /* Super Modern Browsers */
    url('../../../Resources/fonts/LeituraNews-Roman4.woff') format('woff'), /* Modern Browsers */
    url('../../../Resources/fonts/LeituraNews-Roman4.ttf') format('truetype'), /* Safari, Android, iOS */
    url('../../../Resources/fonts/LeituraNews-Roman4.svg#LeituraNews') format('svg'); /* Legacy iOS */
}

@font-face {
    font-family: 'Leitura News Italic';
    font-style: italic;
    font-weight: normal;
    src: url('../../../Resources/fonts/LeituraNews-Italic4.eot'); /* IE9 Compat Modes */
    src: local('Leitura News Italic'), local('Leitura-News-Italic'), url('../../../Resources/fonts/LeituraNews-Italic4.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('../../../Resources/fonts/LeituraNews-Italic4.woff2') format('woff2'), /* Super Modern Browsers */
    url('../../../Resources/fonts/LeituraNews-Italic4.woff') format('woff'), /* Modern Browsers */
    url('../../../Resources/fonts/LeituraNews-Italic4.ttf') format('truetype'), /* Safari, Android, iOS */
    url('../../../Resources/fonts/LeituraNews-Italic4.svg#LeituraNews') format('svg'); /* Legacy iOS */
}

// @font-face {
//     font-family: 'icomoon';
//     src: url("../../../Resources/fonts/icomoon.eot");
//     src: url("../../../Resources/fonts/icomoon.eot#iefix") format("embedded-opentype"), url("../../../Resources/fonts/icomoon.ttf") format("truetype"), url("../../../Resources/fonts/icomoon.woff") format("woff"), url("../../../Resources/fonts/icomoon.svg#icomoon") format("svg");
//     font-weight: normal;
//     font-style: normal;
// }

// @mixin custom-icon {
//     /* use !important to prevent issues with browser extensions that change fonts */
//     font-family: 'icomoon' !important;
//     speak: none;
//     font-style: normal;
//     font-weight: normal;
//     font-variant: normal;
//     text-transform: none;
//     line-height: 1;
//     /* Better Font Rendering =========== */
//     -webkit-font-smoothing: antialiased;
//     -moz-osx-font-smoothing: grayscale;
// }
