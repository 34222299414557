
.a-small-paragraph {
    color: $grey;
    line-height: 1.1875rem;
    font-size: 0.94rem;
}

.a-paragraph {
    color: $black;
    line-height: 1.1875rem;
    font-size: 0.94rem;
    font-size: 1.125rem;
    line-height: 1.563rem;
}

.a-topptekst {
    color: $black;
    font-family: $font-family-roboto-medium;
    font-size: 0.875rem;
    letter-spacing: 2px;
    line-height: 1.125rem;
    text-transform: uppercase;
}

.a-page-title {
    margin-top: 2rem;
    margin-bottom: 1.4375rem;
}
