.i-icon {
	background: url("../../../../../Resources/img/sprite/icons/svg/sprite.svg") no-repeat;
}

.i-andakt {
	background-position: 79.87679671457906% 85.01026694045174%;
	width: 51px;
	height: 51px;
}

.i-andakt-anbefalt {
	background-position: 24.63465553235908% 23.56687898089172%;
	width: 59px;
	height: 67px;
}

.i-andakt-anbefalt-circle-gray {
	background-position: 28.095238095238095% 54.523809523809526%;
	width: 118px;
	height: 118px;
}

.i-andakt-anbefalt-circle-white {
	background-position: 0 82.61904761904762%;
	width: 118px;
	height: 118px;
}

.i-arrangement {
	background-position: 24.229979466119097% 36.550308008213555%;
	width: 51px;
	height: 51px;
}

.i-arrangement-anbefalt {
	background-position: 81.70212765957447% 72.14137214137214%;
	width: 68px;
	height: 57px;
}

.i-arrangement-anbefalt-circle-gray {
	background-position: 0 26.428571428571427%;
	width: 118px;
	height: 118px;
}

.i-arrangement-anbefalt-circle-white {
	background-position: 43.80952380952381% 0;
	width: 118px;
	height: 118px;
}

.i-arrangement-lokalt {
	background-position: 49.69199178644764% 35.714285714285715%;
	width: 51px;
	height: 48px;
}

.i-arrangement-lokalt-hvit {
	background-position: 68.79271070615034% 26.879271070615033%;
	width: 99px;
	height: 99px;
}

.i-arrow-down {
	background-position: 5.283757338551859% 100%;
	width: 27px;
	height: 27px;
}

.i-arrow-down-blue {
	background-position: 31.702544031311156% 100%;
	width: 27px;
	height: 27px;
}

.i-arrow-down-red {
	background-position: 81.60469667318982% 90.99804305283757%;
	width: 27px;
	height: 27px;
}

.i-arrow-down-white {
	background-position: 15.851272015655578% 100%;
	width: 27px;
	height: 27px;
}

.i-arrow-right {
	background-position: 36.986301369863014% 100%;
	width: 27px;
	height: 27px;
}

.i-arrow-right-blue {
	background-position: 47.55381604696673% 100%;
	width: 27px;
	height: 27px;
}

.i-arrow-right-red {
	background-position: 26.418786692759294% 100%;
	width: 27px;
	height: 27px;
}

.i-arrow-right-red-big {
	background-position: 52.50501002004008% 23.647294589178358%;
	width: 39px;
	height: 39px;
}

.i-arrow-right-white {
	background-position: 42.27005870841487% 100%;
	width: 27px;
	height: 27px;
}

.i-arrow-up {
	background-position: 21.135029354207436% 100%;
	width: 27px;
	height: 27px;
}

.i-arrow-up-blue {
	background-position: 76.32093933463797% 90.99804305283757%;
	width: 27px;
	height: 27px;
}

.i-arrow-up-red {
	background-position: 65.75342465753425% 90.99804305283757%;
	width: 27px;
	height: 27px;
}

.i-arrow-up-white {
	background-position: 86.88845401174169% 90.99804305283757%;
	width: 27px;
	height: 27px;
}

.i-blogg {
	background-position: 69.40451745379877% 85.01026694045174%;
	width: 51px;
	height: 51px;
}

.i-blogg-anbefalt {
	background-position: 38.333333333333336% 35.714285714285715%;
	width: 58px;
	height: 48px;
}

.i-blogg-anbefalt-circle-gray {
	background-position: 100% 0;
	width: 118px;
	height: 118px;
}

.i-blogg-anbefalt-circle-white {
	background-position: 0 54.523809523809526%;
	width: 118px;
	height: 118px;
}

.i-camera {
	background-position: 50.38461538461539% 30.07662835249042%;
	width: 18px;
	height: 16px;
}

.i-clock-circle {
	background-position: 98.68937372469% 68.08193376236069%;
	width: 28.32px;
	height: 28.32px;
}

.i-clock-pink-circle {
	background-position: 71.0371819960861% 90.99804305283757%;
	width: 27px;
	height: 27px;
}

.i-close {
	background-position: 52.12355212355212% 98.64864864864865%;
	width: 20px;
	height: 20px;
}

.i-documents {
	background-position: 94.17040358744394% 54.252873563218394%;
	width: 92px;
	height: 103px;
}

.i-download {
	background-position: 98.46153846153847% 60.02321083172147%;
	width: 18px;
	height: 21px;
}

.i-email-circle-black {
	background-position: 18.10865191146881% 93.56136820925553%;
	width: 41px;
	height: 41px;
}

.i-email-circle-pink {
	background-position: 97.4559686888454% 90.99804305283757%;
	width: 27px;
	height: 27px;
}

.i-email-circle-red {
	background-position: 51.10663983903421% 93.56136820925553%;
	width: 41px;
	height: 41px;
}

.i-email-red {
	background-position: 77.26396917148362% 26.295585412667947%;
	width: 19px;
	height: 17px;
}

.i-external-link {
	background-position: 97.29206963249517% 72.59574468085107%;
	width: 21px;
	height: 21px;
}

.i-facebook-black {
	background-position: 59.356136820925556% 93.56136820925553%;
	width: 41px;
	height: 41px;
}

.i-facebook-black-background {
	background-position: 0 94.51219512195122%;
	width: 46px;
	height: 46px;
}

.i-facebook-red {
	background-position: 42.857142857142854% 93.56136820925553%;
	width: 41px;
	height: 41px;
}

.i-globus {
	background-position: 77.26396917148362% 29.672447013487474%;
	width: 19px;
	height: 19px;
}

.i-instagram-black-background {
	background-position: 89.4308943089431% 84.14634146341463%;
	width: 46px;
	height: 46px;
}

.i-logo {
	background-position: 0 0;
	width: 184px;
	height: 56px;
}

.i-logo-black-background {
	background-position: 0 11.594202898550725%;
	width: 184px;
	height: 55px;
}

.i-nyhet {
	background-position: 58.93223819301848% 85.01026694045174%;
	width: 51px;
	height: 51px;
}

.i-nyhet-anbefalt {
	background-position: 73.90396659707724% 47.510373443983404%;
	width: 59px;
	height: 56px;
}

.i-nyhet-anbefalt-circle-gray {
	background-position: 71.9047619047619% 0;
	width: 118px;
	height: 118px;
}

.i-nyhet-anbefalt-circle-white {
	background-position: 100% 28.095238095238095%;
	width: 118px;
	height: 118px;
}

.i-phone-circle {
	background-position: 92.17221135029354% 90.99804305283757%;
	width: 27px;
	height: 27px;
}

.i-pin {
	background-position: 99.42519807363678% 46.303563019934074%;
	width: 23.04px;
	height: 28.32px;
}

.i-pin-pink-circle {
	background-position: 0 100%;
	width: 27px;
	height: 27px;
}

.i-red-hand {
	background-position: 50.86206896551724% 73.67303609341826%;
	width: 74px;
	height: 67px;
}

.i-search {
	background-position: 10.567514677103718% 100%;
	width: 27px;
	height: 27px;
}

.i-search-40x40 {
	background-position: 9.31174089068826% 94.12955465587045%;
	width: 44px;
	height: 44px;
}

.i-search-big {
	background-position: 73.14049586776859% 58.88429752066116%;
	width: 54px;
	height: 54px;
}

.i-search-mobile {
	background-position: 99.41747572815534% 55.79029126213592%;
	width: 23px;
	height: 23px;
}

.i-search-mobile-white {
	background-position: 99.41747572815534% 51.324271844660196%;
	width: 23px;
	height: 23px;
}

.i-sted {
	background-position: 76.8199233716475% 22.736030828516377%;
	width: 16px;
	height: 19px;
}

.i-twitter-black {
	background-position: 26.358148893360163% 93.56136820925553%;
	width: 41px;
	height: 41px;
}

.i-twitter-black-background {
	background-position: 98.78048780487805% 84.14634146341463%;
	width: 46px;
	height: 46px;
}

.i-twitter-red {
	background-position: 34.60764587525151% 93.56136820925553%;
	width: 41px;
	height: 41px;
}

.i-utsyn {
	background-position: 48.459958932238195% 85.01026694045174%;
	width: 51px;
	height: 51px;
}

.i-utsyn-anbefalt {
	background-position: 40% 24.532224532224532%;
	width: 78px;
	height: 57px;
}

.i-utsyn-anbefalt-circle-gray {
	background-position: 56.19047619047619% 54.523809523809526%;
	width: 118px;
	height: 118px;
}

.i-utsyn-anbefalt-circle-white {
	background-position: 28.095238095238095% 82.61904761904762%;
	width: 118px;
	height: 118px;
}

.i-utsyn-globus {
	background-position: 77.13318458105717% 36.35454335615911%;
	width: 18.12px;
	height: 18.12px;
}

.i-utsyn-hand {
	background-position: 66.8103448275862% 73.67303609341826%;
	width: 74px;
	height: 67px;
}

.i-video {
	background-position: 92.81314168377824% 71.25256673511294%;
	width: 51px;
	height: 51px;
}

.i-video-description {
	background-position: 77.26396917148362% 33.14176245210728%;
	width: 19px;
	height: 16px;
}

