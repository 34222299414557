﻿@for $i from 1 through 11 {
    .offset-#{$i} {
        left: percentage($i/12);
    }
    .offset-lg-#{$i} {
        @include media-breakpoint-up(lg) {
            /*left: percentage($i/12);*/
        }
    }
}
