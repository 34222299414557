.m-grid-colored {
    > div {
        height: 100px;
    }

    > div:nth-child(odd) {
        background-color: $main-color;
    }

    > div:nth-child(even) {
        background-color: lighten($main-color, 10%);
    }
}