.o-kampanje-bilde {
    object-fit: cover;
    position: relative;

    .center {
        margin: 0 auto;
        text-align: center;
    }

    &:not(.overlay) {
        img.o-kampanje-bilde__icon, a {
            display: none;
        }
    }

    .tekst-innhold {
        text-align: center;
        text-overflow: ellipsis;
        overflow: hidden;
        position: absolute;
        top: 50%;

        .h1 {
            text-overflow: ellipsis;
            overflow: hidden;
            display: inline-block;
            color: $white;
            text-shadow: 2px 2px 12px rgba(0,0,0,0.22);
            margin-bottom: 1rem;
            line-height: 5rem;
        }

        .a-paragraph {
            max-height: 4.5rem;
            overflow: hidden;
            color: $white;
            text-shadow: 0 2px 6px rgba(0,0,0,0.37);
        }
    }

    @include media-breakpoint-down(xl) {

        .tekst-innhold {
            width: 40%;
            left: 30%;
            transform: translateY(25%);
        }
    }

    @include media-breakpoint-down(lg) {

        .tekst-innhold {
            width: 50%;
            left: 25%;
            transform: translateY(15%);
        }
    }

    @include media-breakpoint-down(md) {
        max-height: none;

        .tekst-innhold {
            position: static;
            width: 100%;
            padding: 0 2rem;
            margin: 2rem 0 3rem;
            left: 0;
            top: 0;
            transform: translateY(0);
            max-height: 13.75rem;

            .h1, .a-paragraph {
                color: $text-color;
                text-shadow: none;
            }
        }
    }

    @include media-breakpoint-down(sm) {
        max-height: none;

        .tekst-innhold {
            max-height: 22rem;

            .a-paragraph {
                max-height: 9.5rem;
            }
        }
    }

    &.overlay {

        img.o-kampanje-bilde__icon {
            display: block;
            width: 63px;
            height: auto;
            margin: 0 auto;
            margin-bottom: 25px;
        }

        .image-container {
            background: white;
            opacity: 0.2;
        }

        .tekst-innhold {
            /*left: 50%;
            transform: translate(-50%, -50%);*/
            .h1 {
                color: $main-color;
                text-shadow: none;
            }

            .a-paragraph {
                color: $main-color;
                font-weight: 500;
                text-shadow: none;
            }

            a {
                margin-top: 1.625rem;
                line-height: 1.188rem;

                .lenketekst {
                    text-transform: uppercase;
                }
            }
        }

        @include media-breakpoint-down(xl) {

            .tekst-innhold {
                transform: translateY(-25%);
            }
        }

        @include media-breakpoint-down(lg) {

            .tekst-innhold {
                transform: translateY(-50%);
            }
        }

        @include media-breakpoint-down(md) {
            .image-container {
                opacity: 1;
            }

            .tekst-innhold {
                max-height: 22rem;
                transform: translateY(0);

                .a-paragraph {
                    font-weight: normal;
                }

                .h1, .a-paragraph {
                    color: $text-color;
                    text-shadow: none;
                }
            }
        }

        @include media-breakpoint-down(sm) {

            .tekst-innhold {
                max-height: 27rem;

                .a-paragraph {
                    max-height: 9.4rem;
                }
            }
        }
    }
}
