﻿.o-ansatt-link-card-search-hit {
    padding: 0;
    min-height: 250px;
    margin-bottom: 2.5rem;
    margin-top: 2rem;

    figure {
        max-height: 250px;
        overflow: hidden;
        object-fit: cover;
        position: relative;
        float: left;
        max-width: 100%;
        width: initial;

        @include media-breakpoint-down(md) {
            width: 100%;
            text-align: center;
        }

        img {
            width: 250px;
            height: 250px;
            margin: 0 auto;
            border-radius: 50%;
            object-fit: cover;
        }
    }

    .o-ansatt-link-card-search-hit__text {
        display: inline-block;
        margin-top: 3.5rem;
        width: calc(100% - 250px);
        padding-left: 2.5rem;

        @include media-breakpoint-down(md) {
            width: 100%;
            text-align: center;
            padding: 0;
        }
    }
}
