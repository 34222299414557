﻿a {
	color: $main-color;
	text-decoration: underline;
}

.a-navbar-toggle {
    display: block;
    padding: 3.3rem 0 2rem;
    margin-right: -20px;


    @include media-breakpoint-up(lg) {
        display: none;
    }

    .a-navbar-toggle__text {
        float: left;
        line-height: 1rem;
        display: block;
        color: $white;
        margin-right: 0.5rem;
        font-weight: bold;
    }

    .a-navbar-toggle__burger-container {
        position: relative;
        width: 20px;
        height: 17px;
        float: left;

        span {
            position: absolute;
            width: 15px;
            height: 2px;
            left: 0;
            background-color: $white;
            transform-origin: left;
            transition: transform 0.5s;

            &:nth-child(1) {
                transform: rotate(45deg);
                top: 0;
            }

            &:nth-child(2) {
                display: none;
                top: 5px;
            }

            &:nth-child(3) {
                transform: rotate(-45deg);
                top: 10px;
            }
        }
    }

    &.collapsed {
        background-color: transparent;

        .a-navbar-toggle__text {
            color: $black;
        }

        .a-navbar-toggle__burger-container {
            span {
                transition: transform 0.5s;
                background-color: $main-color;

                &:nth-child(1) {
                    transform: rotate(0deg);
                }

                &:nth-child(2) {
                    display: block;
                }

                &:nth-child(3) {
                    transform: rotate(0deg);
                }
            }
        }
    }
}

@include media-breakpoint-down(md) {
    .a-header-toggle-link {
        background-color: $main-color;
        color: $white !important;
        padding-left: 0.5rem !important;
        padding-right: 1rem !important;

        &.collapsed {
            background-color: transparent;
            color: $black !important;
        }
    }

    .a-mobile-header-toggle-link {

        &.collapsed {
            background-color: transparent;
            color: $black !important;
        }
    }
}