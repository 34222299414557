﻿.o-aktuelt-navigation-menu {
    padding-top: 0;
    padding-bottom: 0;

    a {
        text-decoration: none;
        font-size: 1.125rem;
        letter-spacing: 1px;
        font-weight: 400;
    }

    .o-aktuelt-navigation-menu__li {
        display: inline-block;

        &:first-child {
            border-top-width: 0;
        }
    }

    .o-aktuelt-navigation-menu__link {
        border-bottom: 1px solid $light-pink;
        text-align: left;
        padding-left: 20px;
        padding-right: 20px;


        .o-aktuelt-navigation-menu__link-icon {
            float: right;
        }

        &:hover {
            .o-aktuelt-navigation-menu__link-icon, .o-aktuelt-navigation-menu__topmenu-link-icon {
                @extend .i-arrow-right-white;
            }
        }

        &.active {
            background-color: $main-color;
            color: blue;
        }
    }

    .o-aktuelt-navigation-menu__li.active {
        .o-aktuelt-navigation-menu__link {
            border-top: 0;
        }
    }

    &.mobile {
        width: 100%;
        display: block;

        .o-aktuelt-navigation-menu__ul-wrapper {
            border: 3px solid $main-color;
            border-left-width: 0;
            border-right-width: 0;

            &.show {
                border-top-color: $light-pink;
            }
        }

        .o-aktuelt-navigation-menu__ul-wrapper:not(.show) + .separator {
            border-top: 3px solid $main-color;
        }

        .o-aktuelt-navigation-menu__link {
            padding-left: 2rem;
            padding-right: 2rem;

            .o-aktuelt-navigation-menu__topmenu-link-icon, .o-aktuelt-navigation-menu__link-icon {
                float: right;
            }
        }

        .o-aktuelt-navigation-menu__li {
            width: 100%;
            display: block;
            padding: 0;

            .o-aktuelt-navigation-menu__link {
                border-radius: 0;
                display: block;
                border-top-width: 0;

                &:hover {
                    color: $main-color;
                    background-color: $lighter-pink;
                }
            }

            .o-aktuelt-navigation-menu__li, .a-pill-li {
                margin-bottom: 0;
            }
        }

        ul.show {
            padding-left: 0;
            border-bottom: 0;
            margin-bottom: 0;

            li {
                padding-left: 20px;
                padding-right: 20px;
            }
        }
    }

    &.top-menu {
        .o-aktuelt-navigation-menu__li {
            border-bottom: 1px solid $light-pink;
        }

        .o-aktuelt-navigation-menu__link {
            border-bottom-width: 0;
        }
    }

    @media (max-width: $screen-md-min) {
        .desktop {
            display: none;
        }
    }

    @media (min-width: $screen-md-min) {
        .mobile {
            display: none;
        }
    }
}
