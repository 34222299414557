﻿.search-hits {
    .link-card-search-hit {
        background-color: $white;
        color: $black;
        padding: 2rem;
        height: 17.94rem;
        object-fit: cover;
        margin-bottom: 1.875rem;
        display: block;
        text-decoration: none;
        display: flex;
        flex-wrap: wrap;
        align-items: center;

        &:hover {
            .read-more-link__text {
                text-decoration: underline;
            }
        }

        @media (max-width: $screen-lg-min) {
            height: initial;
        }

        figure {
            width: 100%;
            margin: 0 0 1rem;
            @include aspect-ratio(4,3);

            @include media-breakpoint-up(xl) {
                width: 13.81rem;
                height: 13.81rem;
                float: left;
                margin: 0 2rem 0 0;
                @include aspect-ratio(1,1);
            }
        }

        img {
            object-fit: initial;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: auto;
            height: 100%;
            max-height: none;
        }

        &__info {
            flex-shrink: 1;

            @include media-breakpoint-up(xl) {
                flex-basis: 60%;
            }
        }

        &__heading {
            display: inline-block;
        }

        &__text {
            padding-right: 1.25rem;
            text-overflow: ellipsis;
            overflow: hidden;
            margin-bottom: 0.5rem;
            text-decoration: none;

            @media (max-width: $screen-lg-min) {
                min-height: initial;

                .read-more-link {
                    margin-top: 1.25rem;
                }
            }

            p {
                max-height: 6rem;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }

        .read-more-link {
            line-height: 1.188rem;
            color: $black;
            font-weight: 500;

            .__text {
                margin-right: 0.3125rem;
                display: inline-block;
            }

            .i-icon {
                font-size: 1.188rem;
            }
        }
    }
}
