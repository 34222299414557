.m-video-block {
    margin-bottom: 2.25rem;

    &__description {
        margin-bottom: 0;
        clear: left;
        display: block;
        padding: 0.25rem 0 0.25rem 0;
        line-height: 1.25rem;

        strong {
            margin-right: 0.3rem;
            text-transform: uppercase;
            float: left;
        }

        span {
            font-style: italic;
        }
    }

    &__photographer {
        color: $photographer-color;
    }

    &__video-ikon {
        margin-right: 0.3rem;
        vertical-align: -1px;
    }

    .video-container {
        position: relative;
        padding-bottom: 56.25%;
        height: 0;
        overflow: hidden;
        margin-bottom: .5rem;

        iframe {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
    }

    .a-wide-container > &, .start-page > & {
        padding-top: 3rem;

        @include make-container;

        @include media-breakpoint-up(sm) {
            max-width: map-get($container-max-widths, "sm");
        }

        @include media-breakpoint-up(md) {
            max-width: map-get($container-max-widths, "md");
        }

        @include media-breakpoint-up(lg) {
            max-width: map-get($container-max-widths, "lg");
        }

        @include media-breakpoint-up(xl) {
            max-width: map-get($container-max-widths, "xl");
        }
        
        &:last-child {
            padding-bottom: 3rem;
        }
    }
}
