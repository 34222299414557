blockquote {
    font-size: $blockquote-font-size;
    font-family: $quote-font-family;
    text-align: center;
    font-style: italic;
    line-height: 3.125rem;
    max-width: 560px;
    margin: 3rem auto 1.5rem auto;
    color: $main-color;

    &:before, &:after {
        border-style: solid;
        border-width: 3px 0 0 0;
        width: 40%;
        content: " ";
        display: block;
        margin: auto;
        border-color: $small-element-color;
    }

    &:before {
        padding-bottom: 15px;
    }

    &:after {
        margin-top: 15px;
    }

    @include media-breakpoint-down(sm) {
        font-size: 2rem;
        line-height: 2.8rem;
    }
}

.a-blockquote-author {
    font-size: 1.125rem;
    font-family: $font-family-roboto-regular;
    font-style: normal;
    font-weight: normal;
    display: block;
    text-align: center;
    text-transform: uppercase;
    letter-spacing: 2px;
    padding: 15px;
    padding-bottom: 0;
}
