// Header section for mobile
.header-mobile {
    width: 100%;
    margin-right: -15px;
    border-bottom: 2px solid $main-color;
    display: flex;
    align-items: center;

    img {
        width: initial;
    }

    .i-icon {
        vertical-align: middle;
    }

    a {
        color: $black;
        font-weight: 500;
        text-decoration: none;
    }

    .o-support-menu {
        &__logo__link {
            margin-left: 0.5rem;
            margin-right: 0.5rem;
        }
    }

    .a-header-navbar-search-toggle {
        padding: 34px 0.5rem 39px;

        .header__show-search-link__icon--mobile {
            @extend .i-search-mobile-white;
        }

        &.collapsed {
            .header__show-search-link__icon--mobile {
                @extend .i-search-mobile;
            }
        }
    }

    .a-header-navbar-menu-toggle {
        margin-left: auto;

        .a-header-navbar-menu-toggle__text {
            color: $white;
        }

        .a-header-navbar-menu-toggle__burger-container {
            background-color: $main-color;
            color: $white;
            padding: 34px 0.5rem 39px;

            .burger-menu {
                width: 20px;
                height: 13px;
                position: relative;
                display: inline-block;
                color: $white;
                vertical-align: middle;

                span {
                    position: absolute;
                    display: inline-block;
                    background-color: $white;
                    border-radius: 35px;
                    transform-origin: left;
                    transition: transform 0.5s;
                    margin-bottom: 2px;
                    width: 15px;
                    height: 2px;
                    left: 0;

                    &:nth-child(1) {
                        transform: rotate(45deg);
                        top: 0;
                    }

                    &:nth-child(2) {
                        display: none;
                        top: 5px;
                    }

                    &:nth-child(3) {
                        transform: rotate(-45deg);
                        top: 10px;
                    }
                }
            }

            @include media-breakpoint-up(md) {
                &:hover {
                    background-color: $lighter-pink;

                    .burger-menu span {
                        background-color: $main-color;
                    }

                    .a-header-navbar-menu-toggle__text {
                        color: $main-color;
                    }
                }
            }
        }

        &.collapsed {

            .a-header-navbar-menu-toggle__text {
                color: $black;
            }

            .a-header-navbar-menu-toggle__burger-container {
                background-color: $white;

                .burger-menu {
                    vertical-align: middle;

                    span {
                        width: 100%;
                        border-radius: 35px;
                        position: absolute;
                        transition: transform 0.5s;
                        background-color: $main-color;

                        &:nth-child(1) {
                            transform: rotate(0deg);
                        }

                        &:nth-child(2) {
                            display: block;
                        }

                        &:nth-child(3) {
                            transform: rotate(0deg);
                        }
                    }
                }
            }
        }
    }

    .nav-link {
        display: inline-block;
        padding-right: 0;
    }
}
