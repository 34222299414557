.m-submenu {
    $submenu: &;

    border: 2px solid $small-element-color;
    border-top-width: 0;
    list-style: none;
    hyphens: auto;

    &__top-header-area, &__links {
        margin-left: auto;
        margin-right: auto;
        padding-left: 20px;
        padding-right: 20px;
        width: 100%;
    }

    &__links {
        @include media-breakpoint-up(lg) {
            padding-bottom: 3.4rem;
        }
    }

    &__header {
        font-family: $font-family-roboto-regular;
        font-size: 1.375rem;
        border-bottom: 2px solid $main-color;
        padding-bottom: 5px;
        padding-top: 2rem;
        line-height: 1.5625rem;
        display: block;
    }

    &__link {
        text-decoration: none;
        color: $black;
        border-bottom: 2px solid $light-gray;
        font-size: 1.125rem;
        margin-bottom: 0;
        display: block;
        position: relative;
        padding-top: 0.8rem;
        padding-bottom: 0.8rem;

        @include media-breakpoint-up(lg) {
            padding: 0.8rem 2.7rem 0.8rem 0.8rem;
        }

        &:hover {
            background-color: $small-element-color;

            #{$submenu}__icon {
                right: 15px;
                @extend .i-arrow-right-white;
            }
        }
    }

    &__icon {
        position: absolute;
        right: 10px;
        top: calc(50% - 11.5px);

        @include media-breakpoint-up(lg) {
            right: 0;
        }
    }
    // Mobile styles
    &__mobile.show, &__mobile.collapsing {
        border-width: 0;
        margin-bottom: 0;

        @include media-breakpoint-up(lg) {
            display: none;
        }

        #{$submenu}__link-container {
            padding: 0 2rem;

            #{$submenu}__links {
                width: 100%;
                padding: 0
            }

            #{$submenu}__link {
                font-weight: 400;
            }

            #{$submenu}__header {
                font-family: $font-family-roboto-regular;
                font-size: 1.3rem;
                border-bottom: 2px solid $main-color;
                line-height: 1.5625rem;
                display: block;
                position: relative;

                #{$submenu}__icon {
                    @extend .i-arrow-up;
                }

                &.collapsed {
                    #{$submenu}__icon {
                        @extend .i-arrow-down;
                    }

                    &:hover {
                        #{$submenu}__icon {
                            @extend .i-arrow-up;
                        }
                    }
                }

                &:hover {
                    background-color: $small-element-color;

                    #{$submenu}__icon {
                        @extend .i-arrow-up-white;
                    }

                    &.collapsed {
                        #{$submenu}__icon {
                            @extend .i-arrow-down-white;
                        }
                    }
                }
            }
        }
    }

    @include media-breakpoint-down(md) {
        &__desktop, &__desktop.show {
            display: none;
        }
    }
}
