﻿/*
    Hyphens to ellipsis mixin
    Use hyphenation when a browser supports the hyphens: auto property,
    else default to text-overflow: ellipsis.
    Used mainly on headings or heading-type text.
*/

@mixin hyphens-to-ellipsis {
    @supports (hyphens: auto) {
        @at-root & {
            hyphens: auto;
        }
    }

    @supports not (hyphens: auto) {
        @at-root & {
            text-overflow: ellipsis;
            overflow: hidden;
            width: 100%;
        }
    }
}

@mixin hyphens-to-break-all {
    @supports (hyphens: auto) {
        @at-root & {
            hyphens: auto;
        }
    }

    @supports not (hyphens: auto) {
        @at-root & {
            word-break: break-all;
        }
    }
}
