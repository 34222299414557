.a-loader {
    position: relative;
    width: 100%;
    margin-bottom: 100px;
    display: block;
    * {
        animation-name: loader;
        animation-duration: 0.5s;
        animation-iteration-count: infinite;
        display: inline-block;
        position: absolute;
    }
}

@keyframes loader {
    0% {left: 0;}
    100% {left: 200px;}
}