﻿.m-factbox-container {
    position: relative;
    overflow: visible;
    margin-bottom: 1rem;
    
    .m-factbox__inner {
        max-height: 3rem;
        overflow: hidden;
        opacity: 0.7;
        transition: max-height 0.5s;
    }

    .m-factbox__gradient {
        background-color: transparent;
        background-image: linear-gradient(transparent, white);
        position: absolute;
        bottom: 5px;
        left: 0;
        height: 50px;
        width: 100%;
    }

    p, ul {
        color: $grey;
        line-height: 1.188rem;
        font-size: 0.94rem;
    }

    p {
        margin-top: 0.5rem;
    }

    li {
        margin-bottom: 1.25rem;
    }

    .m-factbox__show {
        position: absolute;
        left: 0;
        bottom: -10px;
        text-align: center;
        width: 100%;
        padding: 10px 0;
        cursor: pointer;

        span {
            transform: translateY(50%);
        }
    }

    //Animate text
    &.open {
        .m-factbox__inner {
            max-height: 1000px;
            opacity: 1;
            transition: max-height 0.5s;
        }

        .m-factbox__gradient {
            display: none;
        }

        .m-factbox__show {
            transform: rotate(180deg);
            
            span {
                transform: translateY(-50%);
            }
        }
    }
}
