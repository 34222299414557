﻿img {
    width: 100%;
    height: auto;
}

figure {
    position: relative;
    width: 100%;
    overflow: hidden;
    margin: 0;

    img {
        object-position: center;
        object-fit: cover;
        overflow:hidden;
        width: 100%;
        height: 100%;
    }
}

.a-logo-footer {
    width: auto;
    max-height: 70px;
}

// These specifies the style for the image styles available in the EpiServer editor

.m-article-image {
    font-size: 0.875rem;
    margin: 2.25rem 0;

    .m-article-image__description {
        margin-bottom: 0;
        clear: left;
        display: block;
        padding: 0.25rem 0 0.25rem 0;
        line-height: 1.25rem;

        strong {
            margin-right: 0.3rem;
            text-transform: uppercase;
            float: left;
        }

        span {
            font-style: italic;
        }
    }

    span.m-article-image__photographer {
        color: $photographer-color;
    }

    .m-article-image__camera {
        margin-right: 0.3rem;
        vertical-align: -1px;
    }
}

.artikkeltekst .artikkeltekst-innhold > .sideimage {
    position: static;

    @include media-breakpoint-up(md) {
        padding-left: 5rem;
        padding-right: 5rem;
    }

    @include media-breakpoint-up(xl) {
        position: absolute;
        top: 0;
        left: 0px;
        width: calc(12rem - 20px);
        padding-left: 0;
        padding-right: 0;
        margin: 0;
        margin-bottom: 0.5rem;

        .m-article-image__photographer {
            border-bottom: 3px solid $light-pink;
            padding-bottom: 15px;
            display: block;
        }
    }
}

.main-image {
    figure {
        @include media-breakpoint-up(lg) {
            height: 630px;
        }
    }
}