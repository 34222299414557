﻿.o-relatert-innhold {
    &-wrapper {
        background-color: $light-gray;
    }

    &-elementer {
        padding-top: 3.125rem;
        padding-bottom: 3.125rem;

        &__heading {
            display: block;
            margin-bottom: 2.688rem;
            text-align: center;
        }
    }

    &-element {
        background-color: $white;
        display: block;
        text-decoration: none;
        height: 100%;

        &:hover {
            text-decoration: none;

            .les-mer-lenketekst {
                text-decoration: underline;
            }
        }

        &__text {
            padding: 1.375rem 2rem 3.75rem;

            &__dato {
                display: block;
                height: 1rem;
                letter-spacing: normal;
                margin-bottom: 0.5rem;
            }

            &__heading {
                display: inline-block;
                @include hyphens-to-ellipsis;

                @media (max-width: $screen-md-min) {
                    padding-bottom: 0.5rem;
                    margin-bottom: 0;
                }
            }

            p.a-small-paragraph {
                margin-top: 0.375rem;
                margin-bottom: 1rem;

                @media (max-width: $screen-sm-min) {
                    margin-bottom: 2rem;
                }
            }
        }

        &__topptekst {
            background-color: $white;
            top: -0.9375rem;
            left: 1.25rem;
            position: absolute;
            padding: 0.3125rem 1.0625rem 0.3125rem 0.625rem;
            color: $text-color;

            .i-icon {
                &.i-andakt, &.i-blogg, &.i-nyhet, &.i-utsyn, &.i-arrangement, &.i-video {
                    width: 1.875rem;
                    height: 1.875rem;
                    background-size: 330px;
                }
            }

            &__heading {
                margin-left: 0.3125rem;
            }
        }

        figure {
            @include aspect-ratio(16,9);
            overflow: hidden;

            img {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
            }
        }

        .les-mer-lenke {
            line-height: 1.188rem;
            text-decoration: none;
            color: $black;
            font-weight: 500;
            position: absolute;
            bottom: 1.5rem;
            right: 2rem;

            .les-mer-lenketekst {
                margin-right: 0.3125rem;
                display: inline-block;
            }
        }
    }
}
