.felt-page {
    .buttons {
        margin-bottom: 1.25rem;
    }

    .a-preamble {
        margin-bottom: 1.25rem;
    }

    .a-preamble-line:after {
        border-style: solid;
        border-width: 0;
        border-top-width: 3px;
        width: 40%;
        content: " ";
        display: block;
        border-color: $background-color;
        margin: 1.25rem auto 2rem;
    }

    .upper-content-area {
        margin: 1.25rem 0;
    }
}
