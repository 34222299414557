﻿.video-page {
    .video-innhold-wrapper {
        padding-top: 44px;
        margin-bottom: 74px;
    }

    .sharing-options-container {
        margin-top: 1rem;
        text-align: center;


        @media (max-width: $screen-md-min) {
            margin-top: -1rem;
        }
    }

    .m-tag-box-container.m-color-box-container {
        margin-top: 1rem;
    }

    .byline {
        padding-bottom: 2rem;

        .author-name {
            color: $main-color;
            font-weight: 500;
        }
    }

    .knapp-block-container {
        margin: 0;

        .a-btn-pink {
            @media (max-width: $screen-md-min) {
                margin: 1.25rem auto;
            }
        }
    }
}

.video-search-page {
    .a-wide-container--grey > .separator:first-of-type {
        padding-top: 3rem;
        display: block;
    }

    .o-categories-container {
        background-color: transparent;
        padding-top: 1rem;
    }

    .search-results {
        background-color: $gray-100;
    }

    .m-toppseksjon {
        margin-bottom: 1rem;
    }
}
