.o-footer {

    h3, span, a {
        color: $white;
    }

    .o-footer__wide_container {
        background-color: $darkgrey;

        @include media-breakpoint-up(md) {
            padding: 50px;
        }
    }

    .o-footer__h3 {
        border-bottom: 2px solid $white;
        font-size: 1.5625rem;
        padding: 15px 5px;
        margin-top: 15px;
        margin-bottom: 15px;
        display: flex;
        align-items: flex-end;
        height: 93px;

        @include media-breakpoint-up(md) {
            margin-top: 0;
        }
    }

    span {
        display: block;
        line-height: 25px;
    }

    .o-footer__icon-container {
        display: block;
        clear: both;
        margin-bottom: 10px;

        span {
            float: left;
        }

        @include media-breakpoint-down(sm) {
            .i-icon {
                background-size: 356px;
                width: 29px;
                height: 28px;
            }
        }
    }

    .o-footer__social-text {
        font-size: 0.9375rem;
        margin-left: 15px;
        line-height: 25px;

        @include media-breakpoint-up(md) {            
            line-height: 40px;
        }

        &:hover {
            text-decoration: underline;
        }
    }
}
