.m-card {
    $card: &;

    width: 100%;
    color: $main-color;
    background-color: $small-element-color;
    text-align: center;
    padding: 2rem 10% 3rem;
    margin-bottom: 1rem;

    &__title, &__content {
        padding-top: 1rem;
        padding-bottom: 1rem;
    }

    &__title {
        color: $main-color;
        font-size: 1.88rem;
        font-weight: 500;
        display: inline-block;
    }

    &__content {
        font-size: 1rem;
        font-weight: 400;
    }

    &__contact {
        &-list {
            display: inline-block;
            padding-left: 0;
            width: 100%;
        }

        &-li {
            display: flex;
            flex-wrap: wrap;
            line-height: 1.5rem;
            margin-bottom: 0.8rem;
            justify-content: center;
            align-items: center;
        }

        &-icon {
            font-size: 0.55rem;
            margin-right: 15px;
        }
    }

    figure {
        max-height: 160px;
        overflow: hidden;
        object-fit: cover;
        position: relative;
        width: 100%;
    }

    &__image {
        width: 160px;
        height: 160px;
        margin: 0 auto 15px auto;
        border-radius: 50%;
        object-fit: cover;
    }

    &__icon {
        display: block;
        width: 63px;
        height: auto;
        margin: 0 auto;
    }

    &__link {
        text-decoration: none;

        &--email {
            word-break: break-all;
        }
    }

    &--infoblock {
        margin-bottom: 1rem;

        #{$card}__title {
            word-break: break-word;
        }

        #{$card}__content {
            padding-top: 0;
        }
    }

    &--forfatterkort {
        padding: 2rem 18% 3rem;

        #{$card}__content {
            display: block;
        }

        #{$card}__title {
            margin-bottom: 0;
            padding-bottom: 0;
            display: block;
        }
    }

    &--ansattkort {
        background-color: $white;
        padding: 2rem 12% 3rem;
        min-height: 487px;
        margin: 0;
        height: 100%;

        #{$card}__content {
            display: block;
            font-weight: 400;
            color: $text-color;
            min-height: 3rem;
            padding-top: 0;
        }

        #{$card}__title {
            margin-bottom: 0;
            padding-bottom: 1rem;
            display: block;
        }

        #{$card}__contact-icon {
            margin-right: 6px;
        }

        #{$card}__contact-name {
            text-decoration: none;

            &:hover {
                text-decoration: underline;
            }
        }

        .i-icon {
            flex-grow: 0;
            flex-shrink: 0;

            &[class*="i-email"] {
                align-self: flex-start;
                margin-top: 3px;
            }


            + #{$card}__link {
                flex-shrink: 1;
                max-width: calc(100% - 40px)
            }
        }

        span + span {
            margin-left: 0.25rem;
        }

        .no-show {
            display: none;
        }
    }

    &--skribentkort {
        background-color: $white;
        padding: 2rem 12% 3rem;
        min-height: 487px;
        margin-bottom: 2.5rem;

        #{$card}__content {
            display: block;
            font-weight: 500;
            color: $text-color;
            min-height: 3rem;
            padding-top: 0;
            font-size: 18px;
        }

        #{$card}__title {
            margin-bottom: 0;
            padding-bottom: 1rem;
            display: block;
        }
    }


    &--adresseblokk {
        padding: 1rem 10% 3rem;
        margin-bottom: 0;

        .i-icon {
            margin: 0.25rem 0 1rem;
        }

        #{$card}__title {
            padding-bottom: 0;
        }

        #{$card}__content {
            white-space: pre-wrap;
            font-weight: 400;
            padding: 0;
        }
    }

    &--leirinfo {
        text-align: left;
        padding: 2rem 5%;
        color: $black;

        h2.h4 {
            @include typography-small-heading;
        }

        p:last-child {
            margin: 0;
        }
    }

    &--region-card {
        text-align: center;
        height: 261px;
        padding: 1.5rem 18% 3rem;
        margin-bottom: 0;

        .i-icon {
            margin: 0 0 4px;
        }

        #{$card}__title {
            padding-bottom: 0;
            padding-top: 0.75rem;
            width: 100%;
            word-break: normal;
            word-spacing: 2rem;

            @include media-breakpoint-down(md) {
                word-spacing: normal;
            }
        }

        figure {
            margin: 0 auto;
            background-color: white;
            border-radius: 50%;
            width: 95px;
            height: 95px;
        }

        #{$card}__icon {
            width: 46px;
            height: 100%;
            display: block;
            margin-left: auto;
            margin-right: auto;
        }
    }
}
