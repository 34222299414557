// Searchbar appears on button click on top header
.m-search {
    $search: &;

    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    background-color: $small-element-color;
    z-index: 100;

    &__header {
        color: $main-color;
        margin-bottom: 15px;
    }

    &__group {
        position: relative;
        min-width: 70%;

        @include media-breakpoint-up(lg) {
            min-width: 0;
        }
    }

    &__text {
        width: 100%;
        height: 50px;
        border: 2px solid $main-color;
        border-radius: 35px;
        border-top-right-radius: 35px;
        border-bottom-right-radius: 35px;
        padding: 0 20px;
        letter-spacing: 0.05rem;
        outline: none;
        margin: 0 20px;

        &::placeholder {
            color: $main-color;
            font-weight: 500;
        }

        @include media-breakpoint-up(lg) {
            width: 645px;
            height: 60px;
            padding: 0 40px;
        }
    }

    &__btn {
        display: none;

        @include media-breakpoint-up(lg) {
            padding: 0 1.25rem;
            border-radius: 35px;
            text-transform: uppercase;
            letter-spacing: 0.05rem;
            top: 5px;
            right: 25px;
            bottom: 5px;
            z-index: 10;
            position: absolute;
            cursor: pointer;
            display: block;

            i {
                margin-left: 0.5rem;
            }
        }

        &__mobile {
            right: 25px;
            z-index: 10;
            position: absolute;
            height: 100%;
            width: 50px;
            padding: 0;
            cursor: pointer;
            outline: none;
            background: transparent;
            z-index: 10;
            position: absolute;
            cursor: pointer;
            outline: none;
            background: transparent;
            box-shadow: none;

            @include media-breakpoint-up(lg) {
                display: none;
            }

            i {
                margin-left: 0.5rem;
            }
        }
    }

    &--startpage {
        position: static;
        margin-bottom: 2.5rem;

        #{$search}__text::placeholder {
            color: $main-color;
        }

        .query-hit-text {
            margin-top: 2.5rem;
            display: inline-block;
            font-weight: bold;
        }
    }

    &--searchpage {
        padding: 4rem 0;
        margin-left: -1.3rem;
        margin-right: -1.3rem;

        @include media-breakpoint-up(sm) {
            padding: 4rem 2rem 3rem;
            margin-left: 0;
            margin-right: 0;
        }
    }
    // Padding on inner container to have a smoother slideDown jQuery animation
    .container-fluid {
        padding-top: 3rem;

        @include media-breakpoint-down(md) {
            padding-top: 1rem;
        }

        padding-bottom: 3rem;
        display: flex;
        flex-direction: column;
    }
    // hide placeholder text on focus
    input[type=search]:focus::placeholder {
        color: transparent;
    }

    .close-btn-container {
        display: flex;
        flex-direction: row-reverse;
        padding-top: 1.5rem;


        @include media-breakpoint-down(md) {
            padding-top: 1rem;
            margin-top: 0rem;
        }

        button.close {
            float: right;
            background-color: $white;
            width: 2.5rem;
            height: 2.5rem;
            border-radius: 50%;
            border: 0 solid transparent;
            cursor: pointer;

            &:focus {
                box-shadow: none;
            }
        }
    }
}
