.i-icon {
    font-size: 10px;
    text-indent: 200%;
    white-space: nowrap;
    overflow: hidden;
    content: ' ';
    vertical-align: middle;
    display: inline-block;
    background-image: url("../img/sprite/icons/svg/sprite.svg");
    background-repeat: no-repeat;
}
