﻿.a-pill {
    text-decoration: none;
    padding: 0.7rem 1.75rem;
    background-color: $nav-pills-hover-bg;
    border: 1px solid $main-color;
    position: relative;
    color: $nav-pills-color;
    cursor: pointer;

    &:hover {
        font-weight: 500;
        color: $main-color;
    }

    &--active {
        background-color: $nav-pills-link-active-bg;
        color: $main-color;
        font-weight: 400;

        &:after {
            content: "";
            width: 0;
            height: 0;
            border: 5px solid transparent;
            border-top-color: $main-color;
            position: absolute;
            top: 100%;
            left: calc(50% - 5px);

            @media (max-width: $screen-md-min) {
                display: none;
            }
        }
    }

    &--bg-grey {
        background-color: $white;
    }

    &--toggle {
        margin-left: 0.25rem;
        margin-right: 0.25rem;
        margin-bottom: 0.5rem;
    }

    &-li {
        padding-left: 0.25rem;
        padding-right: 0.25rem;
        margin-bottom: 0.5rem;

        @include media-breakpoint-down(md) {
            font-size: 1rem;
        }
    }
}
