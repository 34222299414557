.m-contact-info {
    .m-contact-info__description {
        font-size: 1.31rem;
        line-height: 1.8125rem;
    }

    .m-contact-info__line {
    }

    .i-icon {
        display: block;
        float: left;
        margin-right: 15px;
    }    

    p {
        display: inline-block;
    }
}