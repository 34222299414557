﻿.ressurs-lenkeblokk-utlisting {

    .ressurs-lenkeblokk {
        background-color: white;
        padding: 32px;
        max-height: 285px;
        object-fit: cover;
        margin-bottom: 30px;

        &:last-child {
            margin-bottom: 0;
        }

        img {
            width: 200px;
            height: 200px;
            object-fit: cover;
            float: left;
        }

        .ressurs-lenkeblokk-tekst {
            padding: 30px 20px 0;
            margin-left: 212px;
            max-width: 450px;
            text-overflow: ellipsis;
            overflow: hidden;
            max-height: 12em;

            @media (max-width: $screen-lg-min) {
                max-height: 10.5em;

                .se-mer-lenke {
                    margin-top: 20px;
                }
            }
        }

        .se-mer-lenke {
            line-height: 19px;
            padding-left: 32px;
            text-decoration: none;
            color: $black;
            font-weight: 500;

            .se-mer-lenketekst {
                margin-right: 5px;
                display: inline-block;
            }

            .i-icon {
                font-size: 19px;
            }
        }
    }
}
