.m-breadcrumb {
    .m-breadcrumb__item {
        vertical-align: middle;
        text-decoration: none;
        margin-right: 1.25rem;
        color: $black;

        .m-breadcrumb__name {
            &:hover {
                color: $main-color;
                text-decoration: underline;
            }
        }

        .m-breadcrumb__icon {
            margin-right: 0.625rem;

            @include media-breakpoint-down(sm) {
                background-size: 324px;
                width: 29px;
                height: 29px;
            }
        }
    }
}
