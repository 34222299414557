.artikkeltekst {
    margin: 0 auto 4.375rem;
    background-color: $white;
    overflow-wrap: break-word;
    word-wrap: break-word;
    hyphens: auto;

    @include media-breakpoint-up(md) {
        margin: -8.2rem auto 4.375rem;
    }

    &.no-image {
        @include media-breakpoint-up(md) {
            margin-top: 0;
        }
    }

    .sidetype-elementer {
        padding: 2.125rem 3.125rem 0;

        @include media-breakpoint-down(md) {
            padding: 2rem 1.875rem 0;
        }

        @include media-breakpoint-down(sm) {
            padding: 0 1.875rem 0;
        }

        .m-page-type {
            padding: 0.25rem 1.25rem 1rem;
            margin: 0 auto;
            text-align: center;
        }

        .article-page-heading {
            text-align: center;
            padding: 0;
            margin-bottom: 1.4375rem;
            display: block;

            @include media-breakpoint-up(md) {
                padding: 0 5rem;
            }
        }
    }


    .artikkeltekst-innhold {
        > h2, > h3, > h4, > p, > ul, > ol {
            &:after {
                content: " ";
                display: block;
            }

            @include media-breakpoint-up(md) {
                padding: 0 5rem;
            }

            @include media-breakpoint-up(xl) {
                padding: 0 12rem;
            }
        }

        > ul, > ol {
            margin-left: 1.5rem;
        }

        > table {
            margin: 2rem 0;

            @include media-breakpoint-up(md) {
                margin: 2rem 5rem;
            }

            @include media-breakpoint-up(xl) {
                margin: 2rem 12rem;
            }
        }

        > div:not(.m-more-information-link-block), > figure.textwidth, .fullwidth > figcaption {

            @include media-breakpoint-up(md) {
                padding-left: 5rem;
                padding-right: 5rem;
            }

            @include media-breakpoint-up(xl) {
                padding-left: 12rem;
                padding-right: 12rem;
            }
        }

        .a-preamble {
            text-align: center;

            &:after {
                margin-left: auto;
                margin-right: auto;
            }
        }

        .byline {
            margin-bottom: 1.5rem;
            text-align: center;
            margin-bottom: 1.5rem;

            span {
                line-height: 25px;
            }

            .author-name, .photographer-name, .omraade {
                font-weight: 500;
                color: $main-color;
            }

            .author-name {
                margin-right: -4px; // hack to move comma closer to skribentnavn
            }
        }
    }
}
