﻿.o-content-search {
    &-hit {
        background-color: $white;
        display: block;
        height: 100%;
        text-decoration: none;
        position: relative;

        &__text {
            padding: 1.375rem 2rem 3.75rem;

            &__dato {
                display: block;
                height: 1rem;
                letter-spacing: normal;
                margin-bottom: 0.5rem;

                &__autoheight{
                    height:auto
                }
            }

            &__heading {
                display: block;
                padding-bottom: 0.5rem;
                margin-bottom: 0.5rem;
                @include hyphens-to-ellipsis;
            }
        }

        &-topptekst {
            background-color: $white;
            top: 0;
            left: 1.25rem;
            position: absolute;
            padding: 0.3125rem 1.0625rem 0.3125rem 0.625rem;
            color: $text-color;

            &__heading {
                margin-left: 0.3125rem;
                text-decoration: none;
            }

            .i-icon {
                &.i-andakt, &.i-blogg, &.i-nyhet, &.i-utsyn, &.i-arrangement, &.i-video {
                    width: 1.875rem;
                    height: 1.875rem;
                    background-size: 330px;
                }
            }
        }

        &:hover {
            text-decoration: none;

            .les-mer-lenketekst {
                text-decoration: underline;
            }
        }

        figure {
            overflow: hidden;
            @include aspect-ratio(16, 9);

            img {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
            }
        }

        .a-small-paragraph {
            margin: 0;
        }

        .les-mer-lenke {
            line-height: 1.188rem;
            text-decoration: none;
            color: $black;
            font-weight: 500;
            position: absolute;
            bottom: 1.5rem;
            right: 2rem;

            .les-mer-lenketekst {
                margin-right: 0.3125rem;
                display: inline-block;
            }
        }
    }

    &-hits {
        padding-top: 1.5rem;
        padding-bottom: 3.125rem;

        &-wrapper {
            background-color: $light-gray;
        }
    }
}
