.o-fullskjerm-fremhevede-punkter {
    background-color: $lighter-pink;
    color: $main-color;
    text-align: center;
    display: block;
    padding: 5.813rem 0 6rem;

    a {
        margin-top: 1rem;
    }

    &__heading {
        display: block;
        margin-bottom: 34px;
        color: $main-color;

        @include media-breakpoint-down(sm) {
            padding: 0 2.188rem;
        }
    }

    &-element {
        .h5 {
            display: block;
            margin-top: 21px;
        }

        .center {
            margin: 0 auto;
            text-align: center;
        }

        .image-container {
            img {
                width: auto;
                height: 100%;
                height: 200px;
                width: 200px;
                border-radius: 50%;
            }
        }

        p {
            color: $black;
            margin: 0 auto 2rem;
            min-height: 2rem;
            max-height: 5rem;
            overflow: hidden;
        }

        @include media-breakpoint-up(sm) {
            padding: 0 3rem;

            a {
                margin-top: 0;
            }
        }

        @include media-breakpoint-up(md) {
            padding: 0 4.688rem;
        }

        @include media-breakpoint-up(lg) {
            padding: 0;
        }
    }

    @include media-breakpoint-up(sm) {
        padding-top: 3.125rem;
    }
}