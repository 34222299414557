﻿.m-link-card-list {
    list-style: none;
    padding: 0;
   
    .m-link-card-list__item {
        margin-bottom: 1.1rem;
    }

    .m-link-card-list__item-a {
        text-align: left;
        font-size: 1.125rem;
        font-family: $font-family-roboto-regular;
        display: block;
        text-decoration: none;
        border: solid $lighter-pink;
        border-width: 2px 0 2px 0;
        position: relative;        
        height: 6.4rem;
        padding: 1.5rem 5rem 1.5rem 2rem;
        background-color: $white;
        color: $black;
        line-height: 1.5rem;

        &:hover {
            background-color: $lighter-pink;
        }

        &:before {
            content: " ";
            height: 100%;
            display: inline-block;
            vertical-align: middle;
        }

        .m-link-card-list-item__text {
            display: inline-block;
            vertical-align: middle;
            width: calc(100% - 0.5rem);
        }

        .m-link-card-list-item__icon {
            right: 2rem;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
        }
    }
}
