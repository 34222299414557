﻿.o-anbefalt-innhold-blokk {
    background-color: $white;
    position: relative;
    width: 100%;
    text-decoration: none;
    display: flex;
    flex-wrap: wrap;

    @include media-breakpoint-up(lg) {
        flex-wrap: nowrap;
    }

    &:hover {
        text-decoration: none;

        .les-mer-lenketekst {
            text-decoration: underline;
        }
    }

    figure {
        @include aspect-ratio(4,3);
        width: 100%;
        flex-shrink: 0;

        @include media-breakpoint-up(lg) {
            width: 32.75rem;
        }

        @include media-breakpoint-up(xl) {
            width: 43.75rem;
        }

        img {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            height: 100%;
            width: auto;
            object-fit: cover;
            object-position: center;
        }
    }

    span[class$="anbefalt-circle-gray"], span[class$="anbefalt-circle-white"] {
        position: absolute;
        top: -3.5rem;
        left: 50%;
        transform: translateX(-50%);

        @include media-breakpoint-up(lg) {
            left: -3.563rem;
            top: 4.75rem;
            transform: translateY(0);
            z-index: 1;
        }
    }

    .les-mer-lenke {
        text-decoration: none;
        color: $black;
        font-weight: 500;
        position: relative;

        @include media-breakpoint-up(lg) {
            text-align: right;
            position: absolute;
            right: 0;
            bottom: 2rem;
        }
    }

    .les-mer-lenketekst {
        margin-right: 0.3125rem;
        display: inline-block;
    }

    .m-breadcrumb__item {
        margin-right: 0;
    }
}

.anbefalt-innhold {
    &-blokk-details {
        position: relative;
        z-index: 10;
        height: 100%;
        text-align: center;
        padding: 2rem 0;

        @include media-breakpoint-up(lg) {
            padding-top: 2rem;
            text-align: left;
        }

        @include media-breakpoint-up(xl) {
            padding-top: 3rem;
        }

        @include media-breakpoint-down(md) {
            &.-has-icon {
                padding-top: 4rem;
            }
        }
    }

    &-blokk-tekst {
        padding: 0 2.688rem;
        flex: 1 1 auto;
        position: relative;
        flex-basis: 100%;

        @include media-breakpoint-up(lg) {
            flex-basis: auto;
        }

        p.a-small-paragraph {
            margin-top: 0.8125rem;

            @include media-breakpoint-up(lg) {
                margin-bottom: 5.438rem;
            }
        }
    }

    &-topptekst {
        text-decoration: none;
    }

    &-topptekst__heading {
        font-weight: 500;
    }

    &-tekst__heading {
        display: inline-block;
        margin-top: 0.8125rem;
        max-height: 5.75rem;
        min-height: 2.438rem;
        overflow: hidden;
        line-height: 2.75rem;

        @include media-breakpoint-down(md) {
            font-size: 1.875rem;
        }

        &:first-child {
            margin-top: 0;
        }
    }
}
