﻿.m-color-box-container {
    border-style: solid;
    border-width: 3px;
    background-color: $white;
    border-color: $background-color;
    color: $black;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 2rem;
    min-width: 10.25rem;

    .color-box-heading {
        text-transform: uppercase;
        display: inline-block;
        margin-bottom: 0.75rem;
    }
}
