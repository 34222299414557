﻿.m-tag-box-container {
    padding: 2rem 2rem 0.75rem;

    .tags {
        background-color: $white;
        font-size: 0.9375rem;
        padding-bottom: 1.25rem;

        a.tag {
            background-color: $light-gray;
            color: $black;
            padding: 0.5625rem 1rem 0.4375rem;
            border-radius: 0.25rem;
            margin: 0 0.1875rem 0.625rem 0;
            display: inline-block;
            text-decoration: none;
            line-height: 1.125rem;

            &:hover {
                background-color: $small-element-color;
            }

            @media (max-width: $screen-md-min) {
                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
    }
}
