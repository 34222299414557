﻿.m-pagination {
    display: inline-block;

    .m-pagination__page {
        float: left;
        display: block;
        padding: 0.2rem;
    }

    .m-pagination__page--spacer {
        padding: 0.2rem 0rem;
    }

    .m-pagination__btn {
        text-decoration: none;

        &:not(:hover) {
            @include button-variant($white, $white);
        }

        &:hover {
            @include button-variant($light-pink, $light-pink);
        }
    }

    .m-pagination__disabled {
        display: none;
    }
}
