﻿.o-region-card {
    text-decoration: none;

    &:hover {
        text-decoration: none;

        .link-text {
            text-decoration: underline;
        }
    }

    margin-bottom: 2rem;
    display: block;

    .link-text-wrapper {
        text-decoration: none;
        color: $black;
        display: inline-block;

        .link-text {
            margin-right: 1rem;
            display: inline-block;
        }
    }
}
