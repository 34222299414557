﻿.filtering-underpage {
    padding-top: 3.125rem;
    margin-bottom: 4.375rem;

    .article-page-heading {
        word-break: break-word;

        @include media-breakpoint-down(xs) {
            @include hyphens-to-break-all;
        }
    }

    .top-section {
        margin-bottom: 2.5rem;
    }

    .main-image {
        max-width: 100%;
        width: 100%;
        height: auto;
        margin: 0 auto;
        text-align: center;

        figure img {
            object-fit: cover;
            margin-right: 0;
            width: 320px;
            height: 320px;
            margin: 0 auto 1rem;
        }

        @include media-breakpoint-only(md) {
            width: 250px;
            height: 250px;
            max-height: 250px;
            max-width: 250px;

            figure img {
                width: 250px;
                height: 250px;
                margin: 0;
            }
        }

        @include media-breakpoint-up(lg) {
            width: 300px;
            height: 300px;
            max-height: 300px;
            max-width: 300px;

            figure img {
                width: 300px;
                height: 300px;
                margin: 0;
            }
        }

        @include media-breakpoint-up(xl) {
            width: 420px;
            height: 420px;
            max-height: 420px;
            max-width: 420px;

            figure img {
                width: 420px;
                height: 420px;
                margin: 0;
            }
        }
    }

    .a-preamble-line {
        &:after {
            margin-bottom: 2.25rem;
        }
    }

    .photographer-name {
        font-weight: 500;
        color: $main-color;
    }

    .ressurs-seksjon {
        margin-bottom: 3rem;

        @include media-breakpoint-up(md) {
            .ressurs-lenkekort__heading--no-subheading {
                min-height: 1rem;
                margin-top: 1rem;
                margin-bottom: 0.5rem;
                display: inline-block;
            }
        }

        .ressurs-lenkekort__heading {
            min-height: 1rem;
            margin-top: 1rem;
            margin-bottom: 0.5rem;
            display: inline-block;
        }

        .m-link-card-list .m-link-card-list__item {
            @include media-breakpoint-down(sm) {
                margin-bottom: 0;
            }

            &:first-child {
                .ressurs-lenkekort {
                    @include media-breakpoint-down(sm) {
                        border-top-width: 1px;
                    }
                }
            }

            span.ressurs-lenkekort__heading + div.ressurs-lenkekort {
                border-top-width: 1px;
            }

            .ressurs-lenkekort {
                border: 1px solid $light-pink;
                border-right-width: 0;
                border-left-width: 0;
                background-color: $white;
                padding: 1.25rem 0;
                object-fit: cover;
                margin-bottom: 1.875rem;

                &:last-child {
                    margin-bottom: 0;
                }

                @include media-breakpoint-down(sm) {
                    border-top-width: 0;
                    margin-bottom: 0;
                }

                .ressurs-lenkekort__subheading {
                    margin-top: 0.8rem;
                    display: inline-block;
                    @include hyphens-to-ellipsis;
                }

                img {
                    width: 8.125rem;
                    height: 8.125rem;
                    object-fit: cover;
                    float: left;
                    margin-right: 1.25rem;
                }

                .ressurs-lenkeblokk-tekst {
                    padding-right: 1.25rem;
                    margin-bottom: 0.6rem;
                    @include hyphens-to-ellipsis;
                }

                .i-icon {
                    font-size: 1.188rem;

                    .intern-lenke, .lenke {
                        line-height: 1.188rem;
                    }
                }

                .lenke {
                    .i-external-link, .i-download {
                        margin-right: 0.5rem;
                    }

                    text-decoration: none;

                    &:hover {
                        text-decoration: underline;
                    }
                }

                .intern-lenke {
                    padding-right: 1.25rem;
                    text-decoration: none;
                    color: $black;
                    font-weight: 500;

                    .lenketekst {
                        margin-right: 0.3125rem;
                        display: inline-block;
                    }
                }
            }
        }

        .ressurs-editor {
            border-style: solid;
            border-color: $light-pink;
            border-width: 0;
            border-width: 1px 0;
            margin-top: 1rem;
            padding: 2rem 0 1rem;

            & > p:first-child, & > h4:first-child {
                margin-top: 2rem;
            }
        }
    }
}
