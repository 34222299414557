.m-pills {
    font-size: 1.125rem;
    padding: 0 20px;


    &--third {
        &__pill {
            font-size: 1rem;
            padding: 0.5rem 1.75rem;
        }
    }
}
