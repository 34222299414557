.o-tema-blokk {
    &-tekst {
        margin: -180px 0 56px;
        min-height: 200px;

        @include media-breakpoint-down(lg) {
            min-height: initial;
        }

        .center {
            margin: 0 auto;
            text-align: center;
        }

        .m-breadcrumb {
            padding: 37px 0 22px;

            &__item &__name {
                color: $grey;
                text-transform: uppercase;
            }
        }

        .a-preamble {
            margin-top: 13px;
        }

        ul.lenkeliste {
            margin: 38px 0 0;

            li {
                display: inline-block;
                margin-bottom: 0.5rem;

                @include media-breakpoint-up(lg) {
                    margin-bottom: 0;

                    &:not(:first-child) {
                        margin-left: 20px;
                    }
                }
            }
        }
    }

    img {
        max-height: 28.44rem;
        object-fit: cover;

        @include media-breakpoint-up(lg) {
            object-fit: initial;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            width: 100%;
            height: auto;
            max-height: none;
        }
    }

    @include media-breakpoint-down(md) {
        .artikkeltekst {
            padding: 0 1.25rem;
            margin: 0 0 70px;
        }
    }

    @include media-breakpoint-up(lg) {
        figure {
            position: relative;
            height: 28.44rem;
        }
    }
}

.tema-blokk-heading {
    padding: 0;
    margin-bottom: 1.4375rem;
    display: block;
    word-break: break-word;
    text-decoration: none;

    @include media-breakpoint-up(md) {
        padding: 0 5rem;
    }

    @include media-breakpoint-down(xs) {
        @include hyphens-to-break-all;
    }

    &[href]:hover {
        text-decoration: underline;
    }
}