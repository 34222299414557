.a-btn-pink {
    padding: 1rem 2.25rem;
    text-transform: uppercase;
    border: 2px solid $main-color;
    color: $main-color;
    display: inline-block;
    text-decoration: none;
    border-radius: 100px;
    line-height: 1.31rem;
    font-weight: 500;
    background-color: transparent;
    cursor: pointer;

    &:hover {
        background-color: $main-color;
        color: $white;
        text-decoration: none;
        font-weight: 500;
    }
}

.btn-container {
    overflow-wrap: break-word;
    word-wrap: break-word;
    hyphens: auto;
    word-break: break-word;

    @include media-breakpoint-down(sm) {
        .a-btn-pink {
            padding: 1rem 1.25rem;
        }
    }
}

.button-wrapper {
    padding:  2.5rem 0;
    text-align: center;
}

.buttons {
    a:not(:nth-child(2n+1)) {
        margin-left: 20px;
    }

    a:not(:last-child), a:not(:nth-last-child(-n)) {
        margin-bottom: 20px;
    }
}

button:focus {
    outline: none;
}
