.o-fremhevet-innhold {
    overflow: hidden;
    position: relative;

    &__image {
        position: relative;
        height: 100%;

        @include media-breakpoint-up(lg) {
            position: absolute;
            top: 0;

            img {
                height: auto;
                max-height: none;
                object-fit: fill;
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                width: 100%;
            }
        }
    }

    &__text {
        background-color: $white;
        padding: 2.5rem 0;
        text-align: center;
        width: 100%;
        @include hyphens-to-ellipsis;

        @include media-breakpoint-up(lg) {
            width: 100%;
            padding: 2.688rem 3.75rem;
        }

        .h2 {
            margin-top: 1rem;
            display: inline-block;
            text-decoration: none;

            @include media-breakpoint-up(lg) {
                max-height: 6.5rem;
                padding-bottom: 0.4rem;
                margin-top: 0;
            }

            &[href]:hover {
                text-decoration: underline;
            }
        }

        .a-paragraph {
            margin-top: 0.8125rem;
        }

        a.a-btn-pink {
            margin: 1.625rem 0 0;
            line-height: 1.188rem;

            .lenketekst {
                text-transform: uppercase;
            }
        }
    }

    .center {
        margin: 0 auto;
        text-align: center;
    }
}
