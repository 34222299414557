﻿.toggleable-page-content {
    display: none;

    &.toggleable-page-content--active {
        display: flex;
    }
}

.toggleable-page-content--block {
    display: none;

    &.toggleable-page-content--active {
        display: block;
    }
}