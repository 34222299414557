.m-figure {
    //TODO: Correct line-heights
    .title {
        font-weight: 700;
        text-transform: uppercase;
    }

    .text {
        font-style: $quote-font-family;
    }

    .photo {
        display: block;
    }
}
