﻿.start-page {
    .center {
        margin: auto 0;
    }

    .o-anbefalt-innhold-blokk-wrapper {
        padding-bottom: 3rem;

        @include media-breakpoint-up(lg) {
            padding-bottom: 4rem;
        }

        .o-anbefalt-innhold-blokk {
            background-color: $light-gray;
        }
    }

    .o-content-search-hits-wrapper {
        margin-bottom: 0rem;

        @include media-breakpoint-up(lg) {
            margin-bottom: 0.5rem;
        }

        .o-content-search-hits {
            padding-bottom: 0rem;
            background-color: $white;

            .o-content-search-hit {
                background-color: $light-gray;
            }

            .o-content-search-hit-topptekst, .o-content-search-hit__text {
                background-color: $light-gray;
            }
        }
    }

    .o-tall-blokk {
        margin-bottom: 0rem;

        @include media-breakpoint-up(lg) {
            margin-bottom: 2rem;
        }
    }

    .knapp-block-container {
        padding-bottom: 2rem;

        @include media-breakpoint-up(lg) {
            padding-bottom: 3rem;
        }
    }

    .o-fremhevet-innhold {
        margin-bottom: 0rem;

        @include media-breakpoint-up(lg) {
            margin-bottom: 2rem;
        }

        @include media-breakpoint-down(md) {
            &__text {
                min-height: 0;
            }
        }
    }

    .o-fremhevede-meny-punkter {
        padding-bottom: 4rem;
    }

    .m-card--infoblock {
        margin-bottom: 4rem;
    }

    .button-wrapper {
        padding-top: 0;
    }

    > div:first-child:not(.o-tema-blokk) {
        padding-top: 0;
    }

    > div:last-child {
        margin-bottom: 2rem;
    }
}
