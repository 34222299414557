﻿.m-more-information-link-block-wrapper {
    background-color: $light-gray;
}

.m-more-information-link-block {
    text-align: center;
    width: 100%;
    padding: 0 0 3rem 0;

    .m-more-information-link-block__heading {
        text-transform: uppercase;
        padding: 3rem 0;
        display: inline-block;
        font-family: $smaller-headings-font-family;
        letter-spacing: 2px;
        margin-bottom: 0;
    }
}
