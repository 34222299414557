.o-tall-blokk {
    background-color: $lighter-pink;
    color: $main-color;
    text-align: center;
    padding: 50px 0 0;
    display: block;

    .o-tall-blokk__heading {
        display: block;
        margin-bottom: 30px;
        color: $main-color;
    }

    .o-tall-blokk__lenke {
        padding-bottom: 65px;

        .h1 {
            color: $main-color;
            display: block;
        }

        .o-tall-blokk__antall-merkelapp.h7 {
            display: block;

            &:after {
                border-style: solid;
                border-width: 3px 0 0;
                width: 40%;
                content: " ";
                display: block;
                margin: auto;
                border-color: $white;
                margin-top: 24.5px;
                margin-bottom: 19.5px;
            }
        }

        p {
            color: $black;
            max-width: 360px;
            margin: 0 auto;
            max-height: 4.5rem;
            height: 5rem;
            overflow: hidden;
            text-overflow: ellipsis;
        }

        a {
            margin-top: 30px;
        }
    }
    // screen at least 768px wide
    @include media-breakpoint-up(md) {
        .h1 {
            font-size: $h1-font-size-m;
            line-height: $h1-line-height-m;
        }

        .o-tall-blokk__antall-merkelapp.h7 {
            line-height: $line-height-base;
            font-size: $font-size-base;
        }
    }
    // screen max 767px wide
    @include media-breakpoint-down(sm) {
        .o-tall-blokk__lenke {
            padding: 59px;
            padding-bottom: 65px;
            padding-top: 0;
        }
    }
}
