

//
// Color system
//

$white: #fff !default;
$gray-100: #f9f9f9 !default;
$gray-200: #e9ecef !default;
$gray-300: #dee2e6 !default;
$gray-400: #ced4da !default;
$gray-500: #adb5bd !default;
$gray-600: #868e96 !default;
$gray-700: #495057 !default;
$gray-800: #343a40 !default;
$gray-900: #212529 !default;
$black: #000;

$grays: ( 100: $gray-100, 200: $gray-200, 300: $gray-300, 400: $gray-400, 500: $gray-500, 600: $gray-600, 700: $gray-700, 800: $gray-800, 900: $gray-900 ) !default;

$blue: #004f67;
$indigo: #6610f2 !default;
$purple: #6f42c1 !default;
$pink: #e83e8c !default;
$red: #a02e43;
$orange: #fd7e14 !default;
$yellow: #ffc107 !default;
$green: #28a745 !default;
$teal: #20c997 !default;
$cyan: #17a2b8 !default;
$grey: #373737;
$darkgrey: #332f2e;
$lighter-pink: #cdabaf;

$colors: ( "blue": $blue, "indigo": $indigo, "purple": $purple, "pink": $pink, "red": $red, "orange": $orange, "yellow": $yellow, "green": $green, "teal": $teal, "cyan": $cyan, "white": $white, "gray": $gray-600, "gray-dark": $gray-800 ) !default;

$theme-colors: ( "primary": $red, "secondary": $gray-600, "success": $green, "info": $cyan, "warning": $yellow, "danger": $red, "light": $gray-100, "dark": $gray-800, "white": $white);

// Set a specific jump point for requesting color jumps
$theme-color-interval: 8% !default;

// Theme colors
$theme-red: $red;
$light-pink: #F3E8E9;
$lighter-pink: #F6EEEF;

// Utsyn colors
$utsyn-blue: $blue;
$light-blue: #E1F0ED;
$lighter-blue: #EDF6F4;

// These values are the default colors for the theme and are meant to be overwritten if element is on a Utsyn page
$main-color: $red !default;
$background-color: $lighter-pink !default;
$small-element-color: $light-pink !default;

$text-color: #373737;
$light-gray: $gray-100;
$dark-gray: #E5E5E5;
$white: #fff;

$warning-background: #FFF3DB;
$warning-border: #EB9A00;

/*------------------------------------------------------------------------------*/
// Options // // Quickly modify global styling by enabling or disabling optional features. $enable-rounded: true !default; $enable-shadows: false !default; $enable-gradients: false !default; $enable-transitions: true !default; $enable-hover-media-query: false !default; $enable-grid-classes: true !default; $enable-print-styles: true !default;
/*------------------------------------------------------------------------------*/
// Spacing
//
// Control the default styling of most Bootstrap elements by modifying these
// variables. Mostly focused on spacing.
// You can add more entries to the $spacers map, should you need more variation.
$spacer: 1rem !default;
$spacers: ( 0: 0, 1: ($spacer * .25), 2: ($spacer * .5), 3: $spacer, 4: ($spacer * 1.5), 5: ($spacer * 3) ) !default;

// This variable affects the `.h-*` and `.w-*` classes.
$sizes: ( 25: 25%, 50: 50%, 75: 75%, 100: 100% ) !default;

 
 
/*------------------------------------------------------------------------------*/


// Body
//
// Settings for the `<body>` element.

$body-bg: $white !default;
$body-color: $black;
 
 
/*------------------------------------------------------------------------------*/


// Links
//
// Style anchor elements.

$link-color: theme-color("primary") !default;
$link-decoration: none !default;
$link-hover-color: darken($link-color, 15%) !default;
$link-hover-decoration: underline !default;
 
 
/*------------------------------------------------------------------------------*/


// Grid breakpoints
//
// Define the minimum dimensions at which your layout will change,
// adapting to different screen sizes, for use in media queries.

$grid-breakpoints: ( xs: 0, sm: 576px, md: 768px, lg: 992px, xl: 1200px ) !default;
 
 
/*------------------------------------------------------------------------------*/


// Grid containers
//
// Define the maximum width of `.container` for different screen sizes.

$container-max-widths: ( sm: 540px, md: 720px, lg: 960px, xl: 1160px );
 
 
/*------------------------------------------------------------------------------*/


// Grid columns
//
// Set the number of columns and specify the width of the gutters.

$grid-columns: 12;
$grid-gutter-width: 40px;
 
 
/*------------------------------------------------------------------------------*/

//== Media queries breakpoints
//
//## Define the breakpoints at which your layout will change, adapting to different screen sizes.

$screen-xxs: 320px !default;
//** Deprecated `$screen-xs-min` as of v3.2.0
$screen-xxs-min: $screen-xxs !default;
//** Deprecated `$screen-phone` as of v3.0.1
$screen-small-phone: $screen-xxs-min !default;

// Extra small screen / phone
//** Deprecated `$screen-xs` as of v3.0.1
$screen-xs: 480px !default;
//** Deprecated `$screen-xs-min` as of v3.2.0
$screen-xs-min: $screen-xs !default;
//** Deprecated `$screen-phone` as of v3.0.1
$screen-phone: $screen-xs-min !default;

// Small screen / tablet
//** Deprecated `$screen-sm` as of v3.0.1
$screen-sm: 720px !default; // was 992px for some reason ...
$screen-sm-min: $screen-sm !default;
//** Deprecated `$screen-tablet` as of v3.0.1
$screen-tablet: $screen-sm-min !default;

// Medium screen / desktop
//** Deprecated `$screen-md` as of v3.0.1
$screen-md: 992px !default;
$screen-md-min: $screen-md !default;
//** Deprecated `$screen-desktop` as of v3.0.1
$screen-desktop: $screen-md-min !default;

// Large screen / wide desktop
//** Deprecated `$screen-lg` as of v3.0.1
$screen-lg: 1200px !default;
$screen-lg-min: $screen-lg !default;
//** Deprecated `$screen-lg-desktop` as of v3.0.1
$screen-lg-desktop: $screen-lg-min !default;

// So media queries don't overlap when required, provide a maximum
$screen-xs-max: ($screen-sm-min - 1) !default;
$screen-sm-max: ($screen-md-min - 1) !default;
$screen-md-max: ($screen-lg-min - 1) !default;

/*------------------------------------------------------------------------------*/


// Fonts
//
// Font, line-height, and color for body text, headings, and more.

//Importing fonts

/*
	base-font-size: 16px?
	65px = 4.06rem;
	50px = 3.125rem;
	45px = 2.81rem;
	40px = 2.5rem;
	30px = 1.88rem;
	29px = 1.8125rem;
	25px = 1.5625rem;
	23px = 1.4375rem;
	22px = 1.375rem;
	21px = 1.31rem;
	20px = 1.25rem;
	19px = 1.1875rem;
	18px = 1.125rem;
	15px = 0.94rem;
	14px = 0.875rem;
*/

$font-family-roboto-regular: 'Roboto', sans-serif;
$font-family-roboto-medium: 'Roboto Medium', sans-serif;
$font-family-sans-serif: $font-family-roboto-regular; // This variable overwrites the font-family set by Bootstrap

$font-family-monospace: 'SFMono-Regular', Menlo, Monaco, Consolas, 'Liberation Mono', 'Courier New', monospace !default;
$headings-font-family: 'Leitura News';
$smaller-headings-font-family: $font-family-roboto-medium;
$quote-font-family: 'Leitura News Italic';


$font-size-base: 1rem !default; // Assumes the browser default, typically `16px`
$font-size-lg: 1.25rem !default;
$font-size-sm: .875rem !default;

$font-weight-light: 300 !default;
$font-weight-normal: normal !default;
$font-weight-bold: bold !default;

$font-weight-base: $font-weight-normal !default;
$line-height-base: 1.5625;

$headings-margin-bottom: ($spacer / 2) !default;

$headings-font-weight: 500 !default;
$headings-line-height: 1.1 !default;
$headings-color: $black;

$display1-size: 6rem !default;
$display2-size: 5.5rem !default;
$display3-size: 4.5rem !default;
$display4-size: 3.5rem !default;

$display1-weight: 300 !default;
$display2-weight: 300 !default;
$display3-weight: 300 !default;
$display4-weight: 300 !default;
$display-line-height: $headings-line-height !default;

$lead-font-size: 1.31rem;
$lead-font-weight: $font-weight-normal;

$small-font-size: 0.94rem;

$text-muted: $gray-600 !default;

$blockquote-small-color: theme-colors('primary');
$blockquote-font-size: 2.5rem;

$hr-border-color: rgba($black,.1) !default;
// $hr-border-width: $border-width !default;

$mark-padding: .2em !default;

$dt-font-weight: $font-weight-bold !default;

$kbd-box-shadow: inset 0 -.1rem 0 rgba($black,.25) !default;
$nested-kbd-font-weight: $font-weight-bold !default;

$list-inline-padding: 5px !default;

$mark-bg: #fcf8e3 !default;

//Mobile
$h1-font-size-m: 2.5rem;
$h2-font-size-m: 1.8125rem;
$h3-font-size-m: 1.1875rem;
$h4-font-size-m: 1.875rem;
$h5-font-size-m: 1.25rem;
$h6-font-size-m: 1.125rem;
$h7-font-size-m: 1.125rem;
$h8-font-size-m: 1rem;

$preamble-font-size-m: 1rem;


//Desktop
$h1-font-size: 4.0625rem;
$h2-font-size: 2.8125rem;
$h3-font-size: 2.1875rem;
$h4-font-size: 1.875rem;
$h5-font-size: 1.5rem;
$h6-font-size: 1.5625rem;
$h7-font-size: 1.25rem;
$h8-font-size: 1.125rem;

$preamble-font-size: 1.3125rem;

$h6-letter-spacing: 0.5px;
$h7-letter-spacing: 2.5px;
$h8-letter-spacing: 2px;

//Mobile
$h1-line-height-m: 3.6rem;
$h2-line-height-m: 3.0625rem;
$h3-line-height-m: 2.0625rem;
$h4-line-height-m: 2.4625rem;
$h5-line-height-m: 2.65rem;
$h6-line-height-m: 1.938rem;
$h7-line-height-m: 1.5rem;
$h8-line-height-m: 1.25rem;

$preamble-line-height-m: 1.5rem;


//Desktop
$h1-line-height: 4.5625rem;
$h2-line-height: 3.6rem;
$h3-line-height: 2.0625rem;
$h4-line-height: 2.4625rem;
$h5-line-height: 2.5rem;
$h6-line-height: 1.938rem;
$h7-line-height: 1.5rem;
$h8-line-height: 1.3125rem;

$preamble-line-height: 1.8125rem;


/*------------------------------------------------------------------------------*/


// Components
//
// Define common padding and border radius sizes and more.

$line-height-lg: 1.5 !default;
$line-height-sm: 1.5 !default;

$border-width: 1px !default;
$border-color: $gray-200 !default;

$border-radius: 2.188rem;
$border-radius-lg: .3rem !default;
$border-radius-sm: .2rem !default;

$component-active-color: $white !default;
$component-active-bg: theme-color("primary") !default;

$caret-width: .3em !default;

$transition-base: all .2s ease-in-out !default;
$transition-fade: opacity .15s linear !default;
$transition-collapse: height .35s ease !default;
 
 
/*------------------------------------------------------------------------------*/


// Tables
//
// Customizes the `.table` component with basic values, each used across all table variations.

$table-cell-padding: .75rem !default;
$table-cell-padding-sm: .3rem !default;

$table-bg: transparent !default;
$table-accent-bg: rgba($black, .05) !default;
$table-hover-bg: rgba($black, .075) !default;
$table-active-bg: $table-hover-bg !default;

$table-border-width: $border-width !default;
$table-border-color: $gray-200 !default;

$table-head-bg: $gray-200 !default;
$table-head-color: $gray-700 !default;

$table-inverse-bg: $gray-900 !default;
$table-inverse-accent-bg: rgba($white, .05) !default;
$table-inverse-hover-bg: rgba($white, .075) !default;
$table-inverse-border-color: lighten($gray-900, 7.5%) !default;
$table-inverse-color: $body-bg !default;

 
 
/*------------------------------------------------------------------------------*/


// Buttons
//
// For each of Bootstrap's buttons, define text, background and border color.

$input-btn-padding-y: .5rem !default;
$input-btn-padding-x: .75rem !default;
$input-btn-line-height: 1.25 !default;

$input-btn-padding-y-sm: .25rem !default;
$input-btn-padding-x-sm: .5rem !default;
$input-btn-line-height-sm: 1.5 !default;

$input-btn-padding-y-lg: .5rem !default;
$input-btn-padding-x-lg: 1rem !default;
$input-btn-line-height-lg: 1.5 !default;

$btn-font-weight: $font-weight-normal !default;
$btn-box-shadow: inset 0 1px 0 rgba($white,.15), 0 1px 1px rgba($black,.075) !default;
$btn-focus-box-shadow: 0 0 0 3px rgba(theme-color("primary"), .25) !default;
$btn-active-box-shadow: inset 0 3px 5px rgba($black,.125) !default;

$btn-link-disabled-color: $gray-600 !default;

$btn-block-spacing-y: .5rem !default;

// Allows for customizing button radius independently from global border radius
$btn-border-radius: $border-radius !default;
$btn-border-radius-lg: $border-radius-lg !default;
$btn-border-radius-sm: $border-radius-sm !default;

$btn-transition: background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out !default;

 
 
/*------------------------------------------------------------------------------*/


// Forms

$input-bg: $white !default;
$input-disabled-bg: $gray-200 !default;

$input-color: $gray-700 !default;
$input-border-color: rgba($black,.15) !default;
$input-btn-border-width: $border-width !default; // For form controls and buttons
$input-box-shadow: inset 0 1px 1px rgba($black,.075) !default;

$input-border-radius: $border-radius !default;
$input-border-radius-lg: $border-radius-lg !default;
$input-border-radius-sm: $border-radius-sm !default;

$input-focus-bg: $input-bg !default;
$input-focus-border-color: lighten(theme-color("primary"), 25%) !default;
$input-focus-box-shadow: $input-box-shadow, $btn-focus-box-shadow !default;
$input-focus-color: $input-color !default;

$input-placeholder-color: $gray-600 !default;

$input-height-border: $input-btn-border-width * 2 !default;

$input-height-inner: ($font-size-base * $input-btn-line-height) + ($input-btn-padding-y * 2) !default;
$input-height: calc(#{$input-height-inner} + #{$input-height-border}) !default;

$input-height-inner-sm: ($font-size-sm * $input-btn-line-height-sm) + ($input-btn-padding-y-sm * 2) !default;
$input-height-sm: calc(#{$input-height-inner-sm} + #{$input-height-border}) !default;

$input-height-inner-lg: ($font-size-lg * $input-btn-line-height-lg) + ($input-btn-padding-y-lg * 2) !default;
$input-height-lg: calc(#{$input-height-inner-lg} + #{$input-height-border}) !default;

$input-transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s !default;

$form-text-margin-top: .25rem !default;

$form-check-margin-bottom: .5rem !default;
$form-check-input-gutter: 1.25rem !default;
$form-check-input-margin-y: .25rem !default;
$form-check-input-margin-x: .25rem !default;

$form-check-inline-margin-x: .75rem !default;

$form-group-margin-bottom: 1rem !default;

$input-group-addon-color: $white;
$input-group-addon-bg: $red;
$input-group-addon-border-color: $red;
$input-group-btn-border-color: $red;

$custom-control-gutter: 1.5rem !default;
$custom-control-spacer-y: .25rem !default;
$custom-control-spacer-x: 1rem !default;

$custom-control-indicator-size: 1rem !default;
$custom-control-indicator-bg: #ddd !default;
$custom-control-indicator-bg-size: 50% 50% !default;
$custom-control-indicator-box-shadow: inset 0 .25rem .25rem rgba($black,.1) !default;

$custom-control-indicator-disabled-bg: $gray-200 !default;
$custom-control-description-disabled-color: $gray-600 !default;

$custom-control-indicator-checked-color: $white !default;
$custom-control-indicator-checked-bg: theme-color("primary") !default;
$custom-control-indicator-checked-box-shadow: none !default;

$custom-control-indicator-focus-box-shadow: 0 0 0 1px $body-bg, 0 0 0 3px theme-color("primary") !default;

$custom-control-indicator-active-color: $white !default;
$custom-control-indicator-active-bg: lighten(theme-color("primary"), 35%) !default;
$custom-control-indicator-active-box-shadow: none !default;

$custom-checkbox-indicator-border-radius: $border-radius !default;
$custom-checkbox-indicator-icon-checked: str-replace(url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='#{$custom-control-indicator-checked-color}' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3E%3C/svg%3E"), "#", "%23") !default;

$custom-checkbox-indicator-indeterminate-bg: theme-color("primary") !default;
$custom-checkbox-indicator-indeterminate-color: $custom-control-indicator-checked-color !default;
$custom-checkbox-indicator-icon-indeterminate: str-replace(url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 4'%3E%3Cpath stroke='#{$custom-checkbox-indicator-indeterminate-color}' d='M0 2h4'/%3E%3C/svg%3E"), "#", "%23") !default;
$custom-checkbox-indicator-indeterminate-box-shadow: none !default;

$custom-radio-indicator-border-radius: 50% !default;
$custom-radio-indicator-icon-checked: str-replace(url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3E%3Ccircle r='3' fill='#{$custom-control-indicator-checked-color}'/%3E%3C/svg%3E"), "#", "%23") !default;

$custom-select-padding-y: .375rem !default;
$custom-select-padding-x: .75rem !default;
$custom-select-height: $input-height !default;
$custom-select-indicator-padding: 1rem !default; // Extra padding to account for the presence of the background-image based indicator
$custom-select-line-height: $input-btn-line-height !default;
$custom-select-color: $input-color !default;
$custom-select-disabled-color: $gray-600 !default;
$custom-select-bg: $white !default;
$custom-select-disabled-bg: $gray-200 !default;
$custom-select-bg-size: 8px 10px !default; // In pixels because image dimensions
$custom-select-indicator-color: #333 !default;
$custom-select-indicator: str-replace(url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3E%3Cpath fill='#{$custom-select-indicator-color}' d='M2 0L0 2h4zm0 5L0 3h4z'/%3E%3C/svg%3E"), "#", "%23") !default;
$custom-select-border-width: $input-btn-border-width !default;
$custom-select-border-color: $input-border-color !default;
$custom-select-border-radius: $border-radius !default;

$custom-select-focus-border-color: lighten(theme-color("primary"), 25%) !default;
$custom-select-focus-box-shadow: inset 0 1px 2px rgba($black, .075), 0 0 5px rgba($custom-select-focus-border-color, .5) !default;

$custom-select-font-size-sm: 75% !default;
$custom-select-height-sm: $input-height-sm !default;

$custom-file-height: $input-height !default;
$custom-file-width: 14rem !default;
$custom-file-focus-box-shadow: 0 0 0 .075rem $white, 0 0 0 .2rem theme-color("primary") !default;

$custom-file-padding-y: $input-btn-padding-y !default;
$custom-file-padding-x: $input-btn-padding-x !default;
$custom-file-line-height: $input-btn-line-height !default;
$custom-file-color: $input-color !default;
$custom-file-bg: $input-bg !default;
$custom-file-border-width: $input-btn-border-width !default;
$custom-file-border-color: $input-border-color !default;
$custom-file-border-radius: $input-border-radius !default;
$custom-file-box-shadow: $input-box-shadow !default;
$custom-file-button-color: $custom-file-color !default;
$custom-file-button-bg: $input-group-addon-bg !default;

// Form validation
$form-feedback-valid-color: theme-color("success") !default;
$form-feedback-invalid-color: theme-color("danger") !default;

 
 
/*------------------------------------------------------------------------------*/


// Dropdowns
//
// Dropdown menu container and contents.

$dropdown-min-width: 10rem !default;
$dropdown-padding-y: .5rem !default;
$dropdown-spacer: .125rem !default;
$dropdown-bg: $white !default;
$dropdown-border-color: rgba($black,.15) !default;
$dropdown-border-width: $border-width !default;
$dropdown-divider-bg: $gray-200 !default;
$dropdown-box-shadow: 0 .5rem 1rem rgba($black,.175) !default;

$dropdown-link-color: $gray-900 !default;
$dropdown-link-hover-color: darken($gray-900, 5%) !default;
$dropdown-link-hover-bg: $gray-100 !default;

$dropdown-link-active-color: $component-active-color !default;
$dropdown-link-active-bg: $component-active-bg !default;

$dropdown-link-disabled-color: $gray-600 !default;

$dropdown-item-padding-y: .25rem !default;
$dropdown-item-padding-x: 1.5rem !default;

$dropdown-header-color: $gray-600 !default;

 
 
/*------------------------------------------------------------------------------*/


// Z-index master list
//
// Warning: Avoid customizing these values. They're used for a bird's eye view
// of components dependent on the z-axis and are designed to all work together.

$zindex-dropdown: 1000 !default;
$zindex-sticky: 1020 !default;
$zindex-fixed: 1030 !default;
$zindex-modal-backdrop: 1040 !default;
$zindex-modal: 1050 !default;
$zindex-popover: 1060 !default;
$zindex-tooltip: 1070 !default;

 
 
/*------------------------------------------------------------------------------*/


// Navs

$nav-link-padding-y: 1.2rem;
$nav-link-padding-x: 1.2rem;
$nav-link-disabled-color: $gray-600 !default;

$nav-tabs-border-color: #ddd !default;
$nav-tabs-border-width: $border-width !default;
$nav-tabs-border-radius: $border-radius !default;
$nav-tabs-link-hover-border-color: $gray-200 !default;
$nav-tabs-link-active-color: $gray-700 !default;
$nav-tabs-link-active-bg: $body-bg !default;
$nav-tabs-link-active-border-color: #ddd !default;

$nav-pills-border-radius: $border-radius;
$nav-pills-link-active-color: $white;
$nav-pills-link-active-bg: $lighter-pink;

$nav-pills-init-bg: $light-gray;
$nav-pills-hover-bg: $white;
$nav-pills-color: $black;
 
 
/*------------------------------------------------------------------------------*/


// Navbar

$navbar-padding-y: 0;
$navbar-padding-x: $spacer * 0;

$navbar-brand-font-size: $font-size-lg !default;
// Compute the navbar-brand padding-y so the navbar-brand will have the same height as navbar-text and nav-link
$nav-link-height: $navbar-brand-font-size * $line-height-base !default;
$navbar-brand-height: ($font-size-base * $line-height-base + $nav-link-padding-y * 2) !default;
$navbar-brand-padding-y: 0.7rem;

$navbar-toggler-padding-y: .25rem !default;
$navbar-toggler-padding-x: .75rem !default;
$navbar-toggler-font-size: $font-size-lg !default;
$navbar-toggler-border-radius: $btn-border-radius !default;

$navbar-dark-color: rgba($white,.5) !default;
$navbar-dark-hover-color: rgba($white,.75) !default;
$navbar-dark-active-color: rgba($white,1) !default;
$navbar-dark-disabled-color: rgba($white,.25) !default;
$navbar-dark-toggler-icon-bg: str-replace(url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='#{$navbar-dark-color}' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E"), "#", "%23") !default;
$navbar-dark-toggler-border-color: rgba($white,.1) !default;

$navbar-light-color: $black;
$navbar-light-hover-color: $navbar-light-color;
$navbar-light-active-color: $white;
$navbar-light-disabled-color: rgba($black,.3) !default;
$navbar-light-toggler-icon-bg: str-replace(url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='#{$navbar-light-color}' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E"), "#", "%23") !default;
$navbar-light-toggler-border-color: rgba($black,.1) !default;
 
 
/*------------------------------------------------------------------------------*/


// Pagination

$pagination-padding-y: .5rem !default;
$pagination-padding-x: .75rem !default;
$pagination-padding-y-sm: .25rem !default;
$pagination-padding-x-sm: .5rem !default;
$pagination-padding-y-lg: .75rem !default;
$pagination-padding-x-lg: 1.5rem !default;
$pagination-line-height: 1.25 !default;

$pagination-color: $link-color !default;
$pagination-bg: $white !default;
$pagination-border-width: $border-width !default;
$pagination-border-color: #ddd !default;

$pagination-hover-color: $link-hover-color !default;
$pagination-hover-bg: $gray-200 !default;
$pagination-hover-border-color: #ddd !default;

$pagination-active-color: $white !default;
$pagination-active-bg: theme-color("primary") !default;
$pagination-active-border-color: theme-color("primary") !default;

$pagination-disabled-color: $gray-600 !default;
$pagination-disabled-bg: $white !default;
$pagination-disabled-border-color: #ddd !default;

 
 
/*------------------------------------------------------------------------------*/


// Jumbotron

$jumbotron-padding: 2rem !default;
$jumbotron-bg: $gray-200 !default;
 
 
/*------------------------------------------------------------------------------*/


// Cards

$card-spacer-y: .75rem !default;
$card-spacer-x: 1.25rem !default;
$card-border-width: 1px !default;
$card-border-radius: $border-radius !default;
$card-border-color: rgba($black,.125) !default;
$card-inner-border-radius: calc(#{$card-border-radius} - #{$card-border-width}) !default;
$card-cap-bg: rgba($black, .03) !default;
$card-bg: $white !default;

$card-img-overlay-padding: 1.25rem !default;

$card-deck-margin: ($grid-gutter-width / 2) !default;

$card-columns-count: 3 !default;
$card-columns-gap: 1.25rem !default;
$card-columns-margin: $card-spacer-y !default;
 
 
/*------------------------------------------------------------------------------*/


// Tooltips

$tooltip-max-width: 200px !default;
$tooltip-color: $white !default;
$tooltip-bg: $black !default;
$tooltip-opacity: .9 !default;
$tooltip-padding-y: 3px !default;
$tooltip-padding-x: 8px !default;
$tooltip-margin: 0 !default;


$tooltip-arrow-width: 5px !default;
$tooltip-arrow-height: 5px !default;
$tooltip-arrow-color: $tooltip-bg !default;

 
 
/*------------------------------------------------------------------------------*/


// Popovers

$popover-inner-padding: 1px !default;
$popover-bg: $white !default;
$popover-max-width: 276px !default;
$popover-border-width: $border-width !default;
$popover-border-color: rgba($black,.2) !default;
$popover-box-shadow: 0 5px 10px rgba($black,.2) !default;

$popover-header-bg: darken($popover-bg, 3%) !default;
$popover-header-color: $headings-color !default;
$popover-header-padding-y: 8px !default;
$popover-header-padding-x: 14px !default;

$popover-body-color: $body-color !default;
$popover-body-padding-y: 9px !default;
$popover-body-padding-x: 14px !default;

$popover-arrow-width: 10px !default;
$popover-arrow-height: 5px !default;
$popover-arrow-color: $popover-bg !default;

$popover-arrow-outer-width: ($popover-arrow-width + 1px) !default;
$popover-arrow-outer-color: fade-in($popover-border-color, .05) !default;

 
 
/*------------------------------------------------------------------------------*/


// Badges

$badge-color: $white !default;
$badge-font-size: 75% !default;
$badge-font-weight: $font-weight-bold !default;
$badge-padding-y: .25em !default;
$badge-padding-x: .4em !default;

$badge-pill-padding-x: .6em !default;
// Use a higher than normal value to ensure completely rounded edges when
// customizing padding or font-size on labels.
$badge-pill-border-radius: 10rem !default;
 
 
/*------------------------------------------------------------------------------*/


// Modals

// Padding applied to the modal body
$modal-inner-padding: 0.9375rem !default;

$modal-dialog-margin: 10px !default;
$modal-dialog-margin-y-sm-up: 30px !default;

$modal-title-line-height: $line-height-base !default;

$modal-content-bg: $white !default;
$modal-content-border-color: rgba($black,.2) !default;
$modal-content-border-width: $border-width !default;
$modal-content-box-shadow-xs: 0 3px 9px rgba($black,.5) !default;
$modal-content-box-shadow-sm-up: 0 5px 15px rgba($black,.5) !default;

$modal-backdrop-bg: $black !default;
$modal-backdrop-opacity: .5 !default;
$modal-header-border-color: $gray-200 !default;
$modal-footer-border-color: $modal-header-border-color !default;
$modal-header-border-width: $modal-content-border-width !default;
$modal-footer-border-width: $modal-header-border-width !default;
$modal-header-padding: 15px !default;

$modal-lg: 800px !default;
$modal-md: 500px !default;
$modal-sm: 300px !default;

$modal-transition: transform .3s ease-out !default;
 
 
/*------------------------------------------------------------------------------*/


// Alerts
//
// Define alert colors, border radius, and padding.

$alert-padding-y: .75rem !default;
$alert-padding-x: 1.25rem !default;
$alert-margin-bottom: 1rem !default;
$alert-border-radius: $border-radius !default;
$alert-link-font-weight: $font-weight-bold !default;
$alert-border-width: $border-width !default;

 
 
/*------------------------------------------------------------------------------*/


// Progress bars

$progress-height: 1rem !default;
$progress-font-size: .75rem !default;
$progress-bg: $gray-200 !default;
$progress-border-radius: $border-radius !default;
$progress-box-shadow: inset 0 .1rem .1rem rgba($black, .1) !default;
$progress-bar-color: $white !default;
$progress-bar-bg: theme-color("primary") !default;
$progress-bar-animation-timing: 1s linear infinite !default;
$progress-bar-transition: width .6s ease !default;
 
 
/*------------------------------------------------------------------------------*/


// List group

$list-group-bg: $white !default;
$list-group-border-color: rgba($black,.125) !default;
$list-group-border-width: $border-width !default;
$list-group-border-radius: $border-radius !default;

$list-group-item-padding-y: .75rem !default;
$list-group-item-padding-x: 1.25rem !default;

$list-group-hover-bg: $gray-100 !default;
$list-group-active-color: $component-active-color !default;
$list-group-active-bg: $component-active-bg !default;
$list-group-active-border-color: $list-group-active-bg !default;

$list-group-disabled-color: $gray-600 !default;
$list-group-disabled-bg: $list-group-bg !default;

$list-group-action-color: $gray-700 !default;
$list-group-action-hover-color: $list-group-action-color !default;

$list-group-action-active-color: $body-color !default;
$list-group-action-active-bg: $gray-200 !default;
 
 
/*------------------------------------------------------------------------------*/


// Image thumbnails

$thumbnail-padding: .25rem !default;
$thumbnail-bg: $body-bg !default;
$thumbnail-border-width: $border-width !default;
$thumbnail-border-color: #ddd !default;
$thumbnail-border-radius: $border-radius !default;
$thumbnail-box-shadow: 0 1px 2px rgba($black,.075) !default;
$thumbnail-transition: all .2s ease-in-out !default;
 
 
/*------------------------------------------------------------------------------*/


// Figures

$figure-caption-font-size: 0.875rem;
$figure-caption-color: $body-color;
$photographer-color: #6A6A6A;
 
/*------------------------------------------------------------------------------*/


// Breadcrumbs

$breadcrumb-padding-y: .75rem !default;
$breadcrumb-padding-x: 1rem !default;
$breadcrumb-item-padding: .5rem !default;

$breadcrumb-margin-bottom: 1rem !default;

$breadcrumb-bg: initial;
$breadcrumb-divider-color: $gray-600 !default;
$breadcrumb-active-color: theme-color("primary");
$breadcrumb-divider: "/" !default;
 
 
/*------------------------------------------------------------------------------*/


// Carousel

$carousel-control-color: $white !default;
$carousel-control-width: 15% !default;
$carousel-control-opacity: .5 !default;

$carousel-indicator-width: 30px !default;
$carousel-indicator-height: 3px !default;
$carousel-indicator-spacer: 3px !default;
$carousel-indicator-active-bg: $white !default;

$carousel-caption-width: 70% !default;
$carousel-caption-color: $white !default;

$carousel-control-icon-width: 20px !default;

$carousel-control-prev-icon-bg: str-replace(url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='#{$carousel-control-color}' viewBox='0 0 8 8'%3E%3Cpath d='M4 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3E%3C/svg%3E"), "#", "%23") !default;
$carousel-control-next-icon-bg: str-replace(url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='#{$carousel-control-color}' viewBox='0 0 8 8'%3E%3Cpath d='M1.5 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3E%3C/svg%3E"), "#", "%23") !default;

$carousel-transition: transform .6s ease !default;
 
 
/*------------------------------------------------------------------------------*/


// Close

$close-font-size: $font-size-base * 1.5 !default;
$close-font-weight: $font-weight-bold !default;
$close-color: $black !default;
$close-text-shadow: 0 1px 0 $white !default;
 
 
/*------------------------------------------------------------------------------*/


// Code

$code-font-size: 90% !default;
$code-padding-y: .2rem !default;
$code-padding-x: .4rem !default;
$code-color: #bd4147 !default;
$code-bg: $gray-100 !default;

$kbd-color: $white !default;
$kbd-bg: $gray-900 !default;

$pre-color: $gray-900 !default;
$pre-scrollable-max-height: 340px !default;
 
 
/*------------------------------------------------------------------------------*/

// Icons

$icon-path: '../img/icons/';